.cate {
  &-style {
    text-align: center;
    .cate {
      &-title {
        font-size: 25px;
        margin-bottom: 6px;
        &__link {
          color: var(--title-color);
          &:hover {
            color: var(--theme-color);
          }
        }
      }
      &-num {
        display: block;
        font-size: 16px;
        font-weight: 600;
        &__link {
          display: block;
          color: var(--text-color);
        }
      }
      &-figure {
        position: relative;
        margin-bottom: 44px;
      }
      &-circle {
        position: absolute;
        width: 240px;
        height: 240px;
        background-color: var(--theme-color);
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        z-index: -1;
        top: 10px;
        @include respond-below(xl) {
          width: 200px;
          height: 200px;
        }
        &.color1 {
          background-color: #a9b1fc;
        }
        &.color2 {
          background-color: #e9edfe;
        }
        &.color3 {
          background-color: #f2f0dc;
        }
        &.color4 {
          background-color: #f7e2ff;
        }
        &.color5 {
          background-color: #e4ffe2;
        }
        &.color6 {
          background-color: #fff9c3;
        }
      }
    }
    &.style2 {
      margin-bottom: 30px;
      .cate-icon {
        min-width: 110px;
        min-height: 110px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: var(--banner-color2);
        border-radius: 50%;
        margin-bottom: 30px;
      }
      .cate-title {
        font-size: 18px;
        font-weight: 700;
      }
      &.slick-slide.slick-active {
        border-right: 1px solid var(--vs-border-color6);
      }
    }
  }
}

.cate-item {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 30px;
  position: relative;
  img {
    width: 100%;
    border-radius: 30px;
  }
  .cate-name {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 11px;
    background-color: var(--theme-color2);
    border-radius: 999px;
    padding: 12px 20px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    font-family: var(--title-font);
    color: var(--title-color);
    @include smooth-transition;
    &:hover {
      background-color: var(--theme-color);
      color: var(--white-color);
    }
  }
}
