.service-layout2 {
  padding-bottom: 65px;
}
.service-single {
  .blog-body {
    border: 0;
  }
  .blog-img.main-img {
    margin: 0 0 40px 0;
  }
  .blog-content {
    padding: 0;
  }
  .subtitle {
    font-size: 30px;
  }
  blockquote.vs-quote {
    padding: 50px 45px;
  }
}
