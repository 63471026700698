.img-box1 {
  position: relative;
  .img1 {
    text-align: center;
    display: inline-block;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 291px;
      height: 178px;
      background-color: var(--theme-color2);
      bottom: 0;
      right: 0;
      border-radius: 35px 0 0 0;
    }
    .img {
      border-radius: 40px;
    }
  }
  .video-thumb1 {
    margin-top: -166px;
    width: 410px;
    height: 291px;
    margin-left: auto;
    margin-right: -40px;
    position: relative;
    @include respond-below(md) {
      margin-right: 0;
    }
    @include respond-below(sm) {
      width: 100%;
      margin-top: 0;
    }
    .img {
      border-radius: 30px;
    }
    .play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: background-color 0.3s ease;
      &:hover {
        &::before,
        &::after {
          border-color: var(--theme-color2);
        }
        & > i {
          background-color: var(--theme-color2);
          color: var(--title-color);
        }
      }
    }
  }
  .img-card {
    position: absolute;
    border-radius: 30px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 21px 10px 0px rgba(4, 4, 4, 0.05);
    border: 1px solid var(--vs-border-color);
    display: flex;
    max-width: 316px;
    left: -5%;
    top: 70px;
    @include respond-below(xl) {
      top: auto;
      bottom: 0;
      max-width: 370px;
    }
    @include respond-below(lg) {
      left: 0;
    }
    @include respond-below(sm) {
      position: static;
      max-width: 100%;
    }
    .img-side {
      flex: none;
      margin-top: -29px;
    }
    .text {
      margin-bottom: 0;
      padding: 35px 42px 29px 27px;
    }
  }
}

.about-content1 {
  .about-text {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 35px;
    padding-right: 36px;
    @include respond-below(xl) {
      padding-right: 0;
    }
  }
  .about-body {
    padding-left: 85px;
    @include respond-below(md) {
      padding-left: 0;
    }
  }
}

.list-style1,
.list-style2 {
  ul {
    padding-left: 0;
    margin-bottom: 0;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      font-size: 18px;
      gap: 16px;
      color: var(--text-color);
      &:last-child {
        margin-bottom: 0;
      }
      i {
        width: 51px;
        height: 51px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: var(--theme-color2);
        border-radius: 50%;
        border: 3px solid #f0bb10;
      }
    }
  }
}
.about-text {
  line-height: 32px;
  padding-right: 21px;
}

.about-author {
  display: flex;
  gap: 22px;
  .author-img {
    display: inline-flex;
    max-width: 81px;
    height: 81px;
    border-radius: 50%;
    overflow: hidden;
    padding: 6px;
    border: 1px solid var(--vs-border-color);
    box-shadow: 0px 21px 10px 0px rgba(4, 4, 4, 0.05);
    img {
      border-radius: 50%;
    }
  }
  .author-name {
    margin-bottom: 0;
    font-size: 20px;
  }
  .author-desig {
    display: block;
    margin-bottom: 20px;
  }
  .author-content {
    padding-top: 10px;
  }
}

.img-box3 {
  padding-left: 80px;
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-left: auto;
  &::before {
    position: absolute;
    content: "";
    width: 412px;
    height: 374.98px;
    left: auto;
    right: -17px;
    background-color: var(--theme-color2);
    border-radius: 0 30px 0 0;
    z-index: -1;
    top: -17px;
    @include lg {
      display: none;
    }
  }
  @include respond-below(xl) {
    padding-left: 0;
  }
  .img-circle {
    position: absolute;
    width: 427px;
    height: 427px;
    background-color: var(--theme-color2);
    z-index: -1;
    right: -106px;
    top: -106px;
    border-radius: 50%;
    @include respond-below(sm) {
      display: none;
    }
  }
}

.img-box3i {
  display: inline-block;
  position: relative;
  width: 456px;
  @include md {
    width: 100%;
  }
  &::before {
    position: absolute;
    content: "";
    width: 246px;
    height: 310px;
    background-color: var(--theme-color2);
    top: 50%;
    transform: translateY(-50%);
    left: -14px;
    z-index: -1;
    border-radius: 40px 0 0 40px;
    @include lg {
      display: none;
    }
  }
}

.about-content2 {
  padding-left: 94px;
  @include respond-below(lg) {
    padding-left: 0;
  }
}
.simple-link {
  font-weight: 700;
  color: var(--black-color);
  margin-right: 25px;
  &:hover {
    color: var(--theme-color);
  }
}

.img-box5 {
  display: inline-block;
  position: relative;
  .img1,
  .img2,
  .img3 {
    display: inline-block;
    .img {
      border-radius: 30px;
    }
  }
  .img2 {
    margin-top: -72px;
    padding-left: 238px;
    @include respond-below(sm) {
      margin-top: 0;
      padding-left: 0;
    }
  }
  .img3 {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }
}

.item-card {
  padding-right: 60px;
  @include respond-below(lg) {
    padding-right: 0;
  }
  .item-icon {
    border: 2px dotted var(--theme-color);
    display: inline-flex;
    --icon-size: 90px;
    min-width: var(--icon-size);
    width: var(--icon-size);
    height: var(--icon-size);
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    margin-bottom: 20px;
    &::after {
      position: absolute;
      content: "";
      --icon-size: 78.75px;
      min-width: var(--icon-size);
      width: var(--icon-size);
      height: var(--icon-size);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--theme-color);
      border-radius: 50%;
      z-index: -1;
    }
  }
  .item-title {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .item-text {
    margin-bottom: 0;
  }
}

.history-box1 {
  margin-bottom: 60px;
  .img1 {
    border-radius: 50px;
  }
}
.history-content1 {
  .history-text {
    font-size: 18px;
    padding-right: 31px;
    margin-bottom: 35px;
  }
}

.history-video1 {
  position: relative;
  .img1 {
    border-radius: 30px;
    box-shadow: 0px 4px 68px 0px rgba(0, 0, 0, 0.17);
  }
  .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.history-item {
  padding-right: 11%;
  @include respond-below(md) {
    padding-right: 0;
  }
  .history-title {
    margin: -0.14em 0 25px 0;
  }
  .history-text {
    font-size: 18px;
    margin-bottom: 0;
  }
  .list-style3 {
    ul {
      li {
        color: var(--title-color);
      }
    }
  }
}
.history-year {
  --icon-size: 154px;
  min-width: var(--icon-size);
  min-height: var(--icon-size);
  width: var(--icon-size);
  height: var(--icon-size);
  background: var(--theme-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 800;
  font-family: var(--title-font);
  border-radius: 50%;
  color: var(--white-color);
  position: relative;
  &::before {
    position: absolute;
    content: "";
    --icon-size: 170px;
    min-width: var(--icon-size);
    min-height: var(--icon-size);
    width: var(--icon-size);
    height: var(--icon-size);
    border: 3px solid var(--theme-color2);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-color);
    z-index: -1;
  }
  .timeline-line {
    position: absolute;
    content: "";
    width: 3px;
    height: 100%;
    background-color: var(--theme-color2);
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    z-index: -2;
    @include respond-below(xl) {
      height: 40%;
    }
  }
}

.history-img {
  border-radius: 30px;
  img {
    border-radius: 30px;
  }
}

.history-item,
.history-year,
.history-img {
  margin-bottom: 50px;
}

.history-col {
  &:nth-last-child(2) {
    .timeline-line {
      display: none;
      @include respond-below(md) {
        display: block;
      }
    }
  }
}

.history-layout {
  padding: 120px 0 70px 0;
}

.about-ele1 {
  position: absolute;
  right: 153px;
  bottom: 68px;
}
.about-layout2 {
  .ele1 {
    position: absolute;
    bottom: 85px;
    right: 130px;
    @include lg {
      display: none;
    }
  }
}
