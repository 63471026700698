.price-style {
  background-color: var(--white-color);
  background-image: transparent;
  box-shadow: 0px 21px 10px 0px rgba(4, 4, 4, 0.05);
  border: 1px solid var(--theme-color2);
  border-radius: 30px;
  padding: 7px;
  @include smooth-transition;
  .price-header {
    background-color: var(--theme-color);
    border-radius: 30px;
    padding: 45px;
    position: relative;
  }
  .price-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    @include equal-size(61px);
    background-color: var(--theme-color2);
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .price-package {
    color: var(--white-color);
    font-size: 20px;
    font-weight: 700;
  }
  .price-amount {
    font-family: var(--title-font);
    color: var(--white-color);
    font-size: 60px;
    font-weight: 700;
    line-height: 1;
  }
  .price-duration {
    font-size: 16px;
  }
  .price-body {
    padding: 45px;
  }
  .price-text {
    margin-bottom: 0;
    line-height: 27px;
    @include smooth-transition;
  }
  .price-features {
    padding: 25px 0 30px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        font-size: 16px;
        margin-bottom: 15px;
        position: relative;
        padding-left: 15px;
        font-weight: 600;
        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          position: absolute;
          content: "";
          width: 8px;
          height: 8px;
          background-color: var(--theme-color);
          border-radius: 50%;
          left: 0;
          top: 7px;
        }
      }
    }
  }
  .vs-btn {
    display: inline-flex;
  }
  &:hover,
  &.price-active {
    background-color: var(--bg3-color);
    color: var(--white-color);
    border-color: var(--bg3-color);
    .price-text {
      color: var(--white-color);
    }
  }
}
