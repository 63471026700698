.preloader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background-color: $white-color;

  .vs-btn {
    padding: 15px 20px;
    border-radius: 0;
    font-size: 14px;
  }
}

.preloader-inner {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  img {
    display: block;
    margin: 0 auto 10px auto;
  }
}

.loader {
  animation: loaderspin 1.5s infinite;
  height: 50px;
  width: 50px;
  display: block;
  margin: 0 auto;

  &:before,
  &:after {
    border-radius: 50%;
    content: "";
    display: block;
    height: 20px;
    width: 20px;
  }

  &:before {
    animation: ball1 1.5s infinite;
    background-color: var(--theme-color2);
    box-shadow: 30px 0 0 $theme-color;
    margin-bottom: 10px;
  }

  &:after {
    animation: ball2 1.5s infinite;
    background-color: $theme-color;
    box-shadow: 30px 0 0 $title-color;
  }
}

@keyframes loaderspin {
  0% {
    transform: rotate(0deg) scale(0.8);
  }

  50% {
    transform: rotate(360deg) scale(1);
  }

  100% {
    transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 $theme-color;
  }

  50% {
    box-shadow: 0 0 0 $theme-color;
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 $theme-color;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 $title-color;
  }

  50% {
    box-shadow: 0 0 0 $title-color;
    margin-top: -20px;
    transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 $title-color;
    margin-top: 0;
  }
}

$border-color: var(--theme-color2);
$background-color: #333;
$border-radius-big: 6px;
$font-size-header: 16px;

$car-color: $border-color;
$spin-deg: 36deg;

@keyframes spin {
  0% {
    transform: translate(2px, 1px) rotate($spin-deg * 0);
  }
  10% {
    transform: translate(-1px, -3px) rotate($spin-deg * 1);
  }
  20% {
    transform: translate(-2px, 0px) rotate($spin-deg * 2);
  }
  30% {
    transform: translate(1px, 2px) rotate($spin-deg * 3);
  }
  40% {
    transform: translate(1px, -1px) rotate($spin-deg * 4);
  }
  50% {
    transform: translate(-1px, 3px) rotate($spin-deg * 5);
  }
  60% {
    transform: translate(-1px, 1px) rotate($spin-deg * 6);
  }
  70% {
    transform: translate(3px, 1px) rotate($spin-deg * 7);
  }
  80% {
    transform: translate(-2px, -1px) rotate($spin-deg * 8);
  }
  90% {
    transform: translate(2px, 1px) rotate($spin-deg * 9);
  }
  100% {
    transform: translate(1px, -2px) rotate($spin-deg * 10);
  }
}

@keyframes speed {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -3px) rotate(-1deg);
  }
  20% {
    transform: translate(-2px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 3px) rotate(-1deg);
  }
  60% {
    transform: translate(-1px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-2px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes strikes {
  from {
    left: 25px;
  }
  to {
    left: -80px;
    opacity: 0;
  }
}

@keyframes dots {
  from {
    width: 0px;
  }
  to {
    width: 15px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 0.4s both;
}
.loading-window {
  @extend .fadeIn;
  height: 200px;
  left: 50%;
  margin-left: -150px;
  margin-top: -100px;
  position: fixed;
  top: 50%;
  width: 300px;
  z-index: 99;

  .text {
    font-size: $font-size-header;
    position: absolute;
    width: auto;
    top: 75%;
    left: 38%;
    margin: 0 auto;
  }
  .dots {
    display: inline-block;
    width: 5px;
    overflow: hidden;
    vertical-align: bottom;
    animation: dots 1.5s linear infinite;
    transition: 1;
  }
}
.car {
  position: absolute;
  width: 117px;
  height: 42px;
  left: 92px;
  top: 85px;

  .strike {
    position: absolute;
    width: 11px;
    height: 1px;
    background: var(--theme-color);
    animation: strikes 0.2s linear infinite;
  }
  .strike2 {
    top: 11px;
    animation-delay: 0.05s;
  }
  .strike3 {
    top: 22px;
    animation-delay: 0.1s;
  }
  .strike4 {
    top: 33px;
    animation-delay: 0.15s;
  }
  .strike5 {
    top: 44px;
    animation-delay: 0.2s;
  }
  &-detail {
    position: absolute;
    display: block;
    background: $car-color;
    animation: speed 0.5s linear infinite;

    &.spoiler {
      width: 0;
      height: 0;
      top: 7px;
      background: none;
      border: 20px solid transparent;
      border-bottom: 8px solid $car-color;
      border-left: 20px solid $car-color;
    }
    &.back {
      height: 20px;
      width: 92px;
      top: 15px;
      left: 0px;
    }
    &.center {
      height: 35px;
      width: 75px;
      left: 12px;
      border-top-left-radius: 30px;
      border-top-right-radius: 45px 40px;
      border: 4px solid $car-color;
      background: none;
      box-sizing: border-box;
    }
    &.center1 {
      height: 35px;
      width: 35px;
      left: 12px;
      border-top-left-radius: 30px;
    }
    &.front {
      height: 20px;
      width: 50px;
      top: 15px;
      left: 67px;
      border-top-right-radius: 50px 40px;
      border-bottom-right-radius: 10px;
    }
    &.wheel {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      top: 20px;
      left: 12px;
      border: 3px solid var(--theme-color);
      background: linear-gradient(
          45deg,
          transparent 45%,
          $border-color 46%,
          $border-color 54%,
          transparent 55%
        ),
        linear-gradient(
          -45deg,
          transparent 45%,
          $border-color 46%,
          $border-color 54%,
          transparent 55%
        ),
        linear-gradient(
          90deg,
          transparent 45%,
          $border-color 46%,
          $border-color 54%,
          transparent 55%
        ),
        linear-gradient(
          0deg,
          transparent 45%,
          $border-color 46%,
          $border-color 54%,
          transparent 55%
        ),
        radial-gradient(
          $border-color 29%,
          transparent 30%,
          transparent 50%,
          $border-color 51%
        ),
        $background-color;
      animation-name: spin;
    }
    &.wheel2 {
      left: 82px;
    }
  }
}
