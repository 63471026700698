/*
Template Name: Cannabo
Template URL: https://html.vecuro.com/cannabo
Description: Cannabo - Marijuana and CBD Oil HTML5 Template
Author: vecuro
Author URI: https://themeforest.net/user/vecuro_themes
Version: 1.0.0
*/
/*=================================
    CSS Index Here
==================================*/
/*

01. Theme Base
    1.1. Mixin
    1.2. Function
    1.3. Variable
    1.4. Typography
    1.5. Extend
    1.7. Wordpress Default
02. Reset
    2.1. Container
    2.2. Grid
    2.3. Input
    2.4. Slick Slider
    2.5. Mobile Menu
03. Utilities
    3.1. Preloader
    3.2. Buttons
    3.3. Titles
    3.4. Common
    3.6. Font
    3.7. Background
    3.8. Text Color
    3.9. Overlay
    3.10. Animation
04. Template Style
    4.1. Widget
    4.2. Header
    4.3. Footer
    4.4. Breadcumb
    4.5. Pagination
    4.6. Blog
    4.7. Comments
    4.8. Hero Area
    4.9. Error    
    4.10. Feature
    4.11. About
    4.12. Service
    4.13. Counter
    4.14. Team
    4.15. Process
    4.16. Accordion
    4.17. Simple Sections
    4.18. Testimonial
    4.19. Popup Side Menu
    4.20. Price Plan
    4.21. Forms Style
    4.22. Projects
    4.23. Contact
05. Spacing
06. Color Plate

*/
/*=================================
    CSS Index End
==================================*/

/*=================================
   01. Theme Base
==================================*/
/*------------------- 1.1. Mixin -------------------*/
@import "base/mixin";

/*------------------- 1.2. Function -------------------*/
@import "base/function";

/*------------------- 1.3. Variable-------------------*/
@import "base/variable";

/*------------------- 1.4. Reset -------------------*/
@import "base/reset";

/*------------------- 1.5. Responsive -------------------*/
@import "base/responsive";

/*------------------- 1.6. Wordpress Default -------------------*/
@import "base/wpdefault";

/*=================================
    02. Reset
==================================*/
/*------------------- 2.1. Container -------------------*/
@import "reset/container";

/*------------------- 2.2. Grid -------------------*/
@import "reset/grid";

/*------------------- 2.3. Input -------------------*/
@import "reset/input";

/*------------------- 2.4. Slick Slider -------------------*/
@import "reset/slick-slider";

/*------------------- 2.5. Mobile Menu -------------------*/
@import "reset/vsmenu";

/*=================================
    03. Utilities
==================================*/
/*------------------- 3.1. Preloader -------------------*/
@import "utilities/preloader";

/*------------------- 3.2. Buttons -------------------*/
@import "utilities/btns";

/*------------------- 3.3. Titles -------------------*/
@import "utilities/titles";

/*------------------- 3.4. Common -------------------*/
@import "utilities/common";

/*------------------- 3.6. Font -------------------*/
@import "utilities/font";

/*------------------- 3.7. Background -------------------*/
@import "utilities/background";

/*------------------- 3.8. Text Color -------------------*/
@import "utilities/text-color";

/*------------------- 3.9. Overlay -------------------*/
@import "utilities/overlay";

/*------------------- 3.10. Animation -------------------*/
@import "utilities/animation";

/*------------------- 3.11. Range Slider -------------------*/
@import "utilities/range";

/*=================================
    04. Template Style
==================================*/
/*------------------- 4.1. Widget  -------------------*/
@import "template/widgets/widget-default-list";
@import "template/widgets/widget-sidebar";
@import "template/widgets/widget-footer";

/*------------------- 4.2. Header  -------------------*/
@import "template/headers/header";

/*------------------- 4.3. Footer  -------------------*/
@import "template/footers/footer";

/*------------------- 4.4. Breadcrumb  -------------------*/
@import "template/breadcumb/breadcumb-v1";

/*------------------- 4.5. Pagination  -------------------*/
@import "template/sections/pagination-v1";

/*------------------- 4.6. Blog  -------------------*/
@import "template/sections/blog";

/*------------------- 4.7. Comments  -------------------*/
@import "template/sections/comments";

/*------------------- 4.8. Hero Area  -------------------*/
@import "template/sections/hero";

/*------------------- 4.9. Error  -------------------*/
@import "template/sections/error";

/*------------------- 4.10. Feature  -------------------*/
@import "template/sections/feature";

/*------------------- 4.11. About  -------------------*/
@import "template/sections/about";

/*------------------- 4.12. Service  -------------------*/
@import "template/sections/service";

/*------------------- 4.13. Counter  -------------------*/
@import "template/sections/counter";

/*------------------- 4.14. Team  -------------------*/
@import "template/sections/team";

/*------------------- 4.15. Process  -------------------*/
@import "template/sections/process";

/*------------------- 4.16. Accordion  -------------------*/
@import "template/sections/accordion";

/*------------------- 4.17. Simple Sections  -------------------*/
@import "template/sections/simple-sections";

/*------------------- 4.18. Testimonial  -------------------*/
@import "template/sections/testimonoial";

/*------------------- 4.19. Popup Side Menu  -------------------*/
@import "template/sections/sidemenu-v1";

/*------------------- 4.20. Price Plan  -------------------*/
@import "template/sections/price-plan";

/*------------------- 4.21. Forms Style  -------------------*/
@import "template/sections/forms";

/*------------------- 4.22. Projects  -------------------*/
@import "template/sections/project";

/*------------------- 4.23. Contact  -------------------*/
@import "template/sections/contact";

/*------------------- 4.24. Event  -------------------*/
@import "template/sections/event";

/*------------------- 4.25. CTA  -------------------*/
@import "template/sections/cta";

/*------------------- 4.25. CTA  -------------------*/
@import "template/sections/cate";

/*------------------- 4.26. Woocommerce  -------------------*/
@import "template/sections/woocommerce";

/*------------------- 4.27. Shop  -------------------*/
@import "template/sections/shop";

/*------------------- 4.28. Banner  -------------------*/
@import "template/sections/banner";

/*------------------- 4.29. Banner  -------------------*/
@import "template/sections/brand";

/*------------------- 4.30. Banner  -------------------*/
@import "template/sections/step";

/*------------------- 4.31. Banner  -------------------*/
@import "template/sections/calc";

/*------------------- 4.32. Banner  -------------------*/
@import "template/sections/shop-sortbar";

/*------------------- 4.33. Banner  -------------------*/
@import "template/sections/checkout";

/*=================================
    05. Spacing
==================================*/
@import "spacing/spacing";

/*=================================
    06. Color Plate
==================================*/
@import "base/color-plate";
