.hero {
  &-style1 {
    position: relative;
    padding: 70px 0;
    .slick-dots {
      position: absolute;
      bottom: 0;
      left: 50%;
      margin: 0;
      transform: translateX(-50%);
      li {
        button {
          background-color: var(--title-color);
        }
      }
    }
  }
  &-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--bg1-color);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
  &-text {
    font-size: 20px;
    font-weight: 600;
    color: var(--text-color);
    padding-right: 50px;
    @include lg {
      padding-right: 0;
    }
    @include respond-below(sm) {
      font-size: 20px;
    }
  }
  &-title {
    font-weight: 800;
    line-height: 1.15;
    margin: -0.14em 0 25px 0;
  }
  &-subtitle {
    display: flex;
    align-items: center;
    font-family: var(--body-font);
    font-size: 22px;
    font-weight: 600;
    gap: 12px;
    margin-bottom: 45px;
  }
  &-img {
    text-align: center;
    position: relative;
    z-index: 1;
    .circle {
      border-radius: 50%;
      width: 542px;
      height: 542px;
      background-color: var(--white-color);
      display: inline-block;
      position: absolute;
      top: 37px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      @include respond-below(sm) {
        width: 300px;
        height: 300px;
      }
      &.yellow-bg {
        background-color: var(--theme-color2);
      }
    }
  }
  &-leaf {
    position: absolute;
    top: 0;
    left: -109px;
    @include respond-below(md) {
      left: 0;
    }
  }
  &-leaf2,
  &-leaf3 {
    position: absolute;
    @include respond-below(sm) {
      display: none;
    }
  }
  &-leaf2 {
    top: 48px;
    left: 0;
    @include respond-below(md) {
      display: none;
    }
  }
  &-leaf3 {
    top: auto;
    bottom: 48px;
    left: 48px;
    @include respond-below(xlg) {
      display: none;
    }
  }
  &-certificate {
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 0;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-color2);
    padding: 5px;
    & > img {
      animation: spin 10s linear infinite;
    }
    &.white-bg {
      background-color: var(--white-color);
    }
  }
}

.hero-style2 {
  .hero-content {
    padding: 315px 0 170px;
    position: relative;
    z-index: 1;
    @include respond-below(sm) {
      padding: 210px 0 80px;
    }
    .hero-title {
      margin-bottom: 25px;
      font-size: 60px;
      @include sm {
        font-size: 36px;
      }
    }
    .hero-text {
      margin-bottom: 30px;
      max-width: 80%;
      font-weight: 400;
      @include respond-below(sm) {
        max-width: 100%;
      }
    }
  }
}
.hero-carousel2 {
  position: relative;
  .hero-logo {
    position: absolute;
    right: 312px;
    top: 240px;
    @include lg {
      display: none;
    }
  }
  .slick-dots {
    position: absolute;
    bottom: 40px;
    max-width: calc(var(--main-container) + var(--container-gutters));
    padding-left: calc(var(--container-gutters) / 2);
    padding-right: calc(var(--container-gutters) / 2);
    margin-right: auto;
    margin-left: auto;
    right: 0;
    left: 0;
    text-align: left;
    button {
      background-color: var(--dots-bg, #fff);
    }
    .slick-active {
      button {
        background-color: var(--white-color);
        &::before {
          border-color: var(--white-color);
        }
      }
    }
  }
  .hero-leaf1,
  .hero-leaf2 {
    position: absolute;
  }
  .hero-leaf1 {
    top: 216px;
    left: 227px;
  }
  .hero-leaf2 {
    bottom: 160px;
    top: auto;
    left: 66px;
  }
}

.hero-style3 {
  position: relative;
  padding: 225px 0 65px 0;
  @include respond-below(sm) {
    padding: 190px 0 65px 0;
  }
  .slick-dots {
    margin: 20px 0 0 0;
  }
  .hero-shape {
    position: absolute;
    min-width: 45%;
    height: 100%;
    right: 0;
    background-color: var(--theme-color2);
    border-radius: 50px 0 0 50px;
    top: auto;
    bottom: 0;
    @include respond-below(md) {
      display: none;
    }
  }
  .hero-leaf3i3 {
    bottom: 97px;
    right: 97px;
  }
  .hero-leaf3i1 {
    bottom: 232px;
    left: 39px;
    @include xl {
      display: none;
    }
  }
  .hero-leaf3i2 {
    left: auto;
    right: 86px;
    top: 236px;
    @include lg {
      display: none;
    }
  }
}
.hero-content {
  &.style3 {
    margin-bottom: 30px;
    .hero-title {
      font-size: 60px;
      line-height: 83px;
      margin-bottom: 25px;
      @include xl {
        font-size: 50px;
      }
      @include respond-below(sm) {
        font-size: 30px;
        line-height: 40px;
      }
    }
    .hero-text {
      margin-bottom: 40px;
      font-size: 22px;
      font-weight: 400;
      line-height: 1.5;
    }
  }
}
.hero-img {
  &.style3 {
    position: relative;
    margin-bottom: 40px;
    text-align: left;
    .img1 {
      border-radius: 50px;
    }
    .img2 {
      position: absolute;
      bottom: -40px;
      background: white;
      right: 30px;
      border-radius: 50px;
      border: 2px solid var(--theme-color2);
      @include respond-below(sm) {
        position: static;
      }
    }
  }
}

.hero-leaf3i1,
.hero-leaf3i2,
.hero-leaf3i3 {
  position: absolute;
}
