.vs-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999999;
  width: 0;
  width: 100%;
  height: 100%;
  transition: all ease 0.8s;
  opacity: 0;
  visibility: hidden;

  // Mobile Menu Logo
  .mobile-logo {
    padding-bottom: 30px;
    padding-top: 40px;
    display: block;
    text-align: center;
    background-color: var(--bg3-color);
    a {
      display: inline-block;
    }

    svg {
      max-width: 185px;
    }
  }

  // Menu Close Button
  .vs-menu-toggle {
    border: none;
    font-size: 22px;
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 0;
    line-height: 1;
    width: 33px;
    height: 33px;
    line-height: 33px;
    font-size: 18px;
    z-index: 1;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: var(--theme-color2);
      color: $title-color;
    }
  }

  .vs-menu-area {
    width: 100%;
    max-width: 310px;
    background-color: #fff;
    border-right: 3px solid $theme-color;
    height: 100%;
    position: relative;
    left: -110%;
    opacity: 0;
    visibility: hidden;
    transition: all ease 1s;
    z-index: 1;
  }

  &.vs-body-visible {
    opacity: 1;
    visibility: visible;

    .vs-menu-area {
      left: 0;
      opacity: 1;
      visibility: visible;
    }
  }
}

// Mobile Menu
.vs-mobile-menu {
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
  padding-bottom: 40px;
  margin-top: 33px;
  text-align: left;

  .new-label {
    margin-left: 5px;
    text-transform: capitalize;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  ul {
    margin: 0;
    padding: 0 0;

    li {
      border-bottom: 1px solid #fdedf1;
      list-style-type: none;

      li:first-child {
        border-top: 1px solid #fdedf1;
      }

      a {
        display: block;
        position: relative;
        padding: 12px 0;
        line-height: 1;
        font-size: 16px;
        text-transform: capitalize;
        color: $title-color;

        &:before {
          content: "\f105";
          font-family: $icon-font;
          position: relative;
          left: 0;
          top: 0;
          margin-right: 10px;
          display: inline-block;
        }
      }

      &.vs-active {
        > a {
          color: $theme-color;

          &:before {
            transform: rotate(90deg);
          }
        }
      }

      ul {
        li {
          padding-left: 10px;

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }

    .vs-item-has-children {
      > a {
        .vs-mean-expand {
          position: absolute;
          right: 0;
          top: 50%;
          font-weight: 400;
          font-size: 12px;
          width: 25px;
          height: 25px;
          line-height: 25px;
          margin-top: -12.5px;
          display: inline-block;
          text-align: center;
          background-color: $smoke-color;
          color: $title-color;
          box-shadow: 0 0 20px -8px rgba(#ad8858, 0.5);
          border-radius: 50%;
          @include smooth-transition;

          &:before {
            content: "\f067";
            font-family: $icon-font;
          }
        }
      }

      &.vs-active {
        > a {
          .vs-mean-expand:before {
            content: "\f068";
          }
        }
      }
    }
  }

  > ul {
    padding: 0 40px;

    > li {
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.vs-menu-toggle {
  width: 50px;
  height: 50px;
  padding: 0;
  font-size: 24px;
  border: none;
  display: inline-block;
  background-color: #f2f6ff;
  color: $theme-color;
  border-radius: 5px;

  &.style2 {
    background-color: $theme-color;
    border-radius: 50%;
    color: $white-color;
    width: 55px;
    height: 55px;
  }
}

@media (max-width: 400px) {
  .vs-menu-wrapper .vs-menu-area {
    width: 100%;
    max-width: 270px;
  }

  .vs-mobile-menu > ul {
    padding: 0 20px;
  }
}

.menu-item-has-children.vs-item-has-children.vs-active {
  .vs-mean-expand {
    background-color: var(--theme-color);
    @include smooth-transition;
  }
}
