.comment-respond {
  margin: var(--blog-space-y, 60px) 0 30px 0;
  position: relative;

  .form-title {
    a#cancel-comment-reply-link {
      font-size: 0.7em;
      text-decoration: underline;
    }
  }

  .custom-checkbox.notice {
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .row {
    --bs-gutter-x: 20px;
  }

  .form-group {
    &:last-child {
      margin-top: 10px;
    }
  }

  .form-control {
    background-color: var(--white-color);
    height: 60px;
    border: 1px solid var(--vs-border-color4);
    border-radius: 30px;
    padding: 15px 42px;
    color: var(--text-color);
    font-size: 16px;
    &::placeholder {
      color: var(--text-color);
    }
  }
  textarea.form-control {
    padding: 25px 42px;
  }

  .form-group > i {
    top: 20.5px;
  }

  input[type="checkbox"] {
    & ~ label {
      &:before {
        background-color: $white-color;
        border: 1px solid $theme-color;
        border-radius: 3px;
      }
    }

    &:checked {
      ~ label {
        &:before {
          background-color: $theme-color;
          border-color: transparent;
        }
      }
    }
  }

  .form-text {
    margin-bottom: 25px;
  }
}

.question-form,
.review-form {
  .comment-respond {
    margin: 0 0 20px 0;
  }

  .form-control {
    background-color: transparent;
    border: 1px solid #e4e4e4;
  }

  .rating-select {
    display: flex;
    align-items: center;

    label {
      font-weight: 500;
      color: $title-color;
    }
  }
}

.vs-comments-wrap {
  margin: var(--blog-space-y, 60px) 0 var(--blog-space-y, 60px) 0;

  .description p:last-child {
    margin-bottom: 0;
  }

  .comment-respond {
    margin: 30px 0;
  }

  pre {
    background: #ededed;
    color: #666;
    font-size: 14px;
    margin: 20px 0;
    overflow: auto;
    padding: 20px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  blockquote {
    background-color: #eaf8f9;
  }

  li {
    margin: 0;
  }

  ul.comment-list {
    list-style: none;
    padding: 0;

    ul,
    ol {
      ul,
      ol {
        margin-bottom: 0;
      }
    }
  }

  .comment-top {
    display: flex;
    justify-content: space-between;
  }

  .text {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .children {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-left: 70px;
    .replay-btn {
      background-color: var(--theme-color);
    }
  }

  .replay-btn {
    font-weight: 700;
    font-size: 13px;
    font-family: var(--body-font);
    position: absolute;
    right: 15px;
    top: 15px;
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    background: var(--title-color);
    color: var(--white-color);
    padding: 10px 17px 7px;
    border-radius: 999px;
    line-height: 1;
    z-index: 1;

    i {
      margin-right: 7px;
    }

    &:hover {
      background-color: var(--theme-color2);
      color: $white-color;
    }
  }

  .star-rating {
    font-size: 12px;
    margin-bottom: 10px;
    position: absolute;
    top: 5px;
    right: 0;
    width: 80px;
  }
}

.vs-post-comment {
  display: flex;
  position: relative;
  padding: 29px 40px;
  margin: 20px 0 20px 0;
  border: 1px solid var(--vs-border-color3);
  border-radius: 30px;
  align-items: center;
  z-index: 1;
  @include respond-below(sm) {
    padding: 50px 15px 15px 15px;
    flex-direction: column;
    gap: 40px;
  }
  &::before {
    position: absolute;
    content: "";
    width: 110px;
    height: 100%;
    background-color: var(--bg2-color);
    z-index: -1;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 30px;
    @include respond-below(sm) {
      display: none;
    }
  }
}
.comment-avater {
  --image-size: 130px;
  width: var(--image-size);
  height: var(--image-size);
  margin-right: 28px;
  overflow: hidden;
  background-color: var(--white-color);
  border-radius: 50%;
  border: 3px solid var(--white-color);
  box-shadow: 0px 13px 9px 0px rgba(0, 0, 0, 0.15);

  img {
    width: 100%;
  }
}

.comment-content {
  flex: 1;
  position: relative;
  .name {
    margin-bottom: 7px;
    font-size: 24px;
    font-weight: 700;
    display: flex;
    align-items: flex-end;
    gap: 13px;
    font-family: var(--body-font);
  }
  .text {
    margin-bottom: 0;
  }
  .commented-on {
    font-size: 14px;
    display: block;
    margin-bottom: 4px;
    margin-top: 0;
    font-weight: 600;
    color: var(--text-color);
    text-transform: uppercase;

    i {
      margin-right: 7px;
      font-size: 0.9rem;
    }
  }
}

.vs-comments-wrap.vs-comment-form {
  margin: 0;
}

@include lg {
  .vs-comments-wrap {
    .vs-post-comment {
      display: block;
    }

    .star-rating {
      position: relative;
      top: 0;
      right: 0;
    }

    .comment-top {
      display: block;
    }

    .comment-avater {
      margin-right: 0;
      margin-bottom: 20px;
    }

    .children {
      margin-left: 40px;
    }

    .reply_and_edit {
      position: relative;
      top: 0;
      margin: 15px 0 0 0;
    }
  }
}

@include sm {
  .vs-comments-wrap {
    .children {
      margin-left: 20px;
    }

    .name {
      font-size: 18px;
    }
  }
}
