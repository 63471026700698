.sec-icon {
  display: inline-block;
  position: relative;
  z-index: 1;
  margin: -0.07em 0 20px 0;
  &::before {
    position: absolute;
    content: "";
    height: 1px;
    background-color: var(--theme-color);
    width: 182px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
}

.sec-title {
  text-transform: capitalize;
  line-height: 1.35;
  margin: -0.25em 0 18px 0;
}

.sec-title2 {
  font-size: 38px;
  text-align: center;
  margin: -10px 0 30px 0;
  i {
    margin-right: 15px;
  }
}

.sec-subtitle {
  text-transform: uppercase;
  display: block;
  color: var(--theme-color);
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 900;
  margin: -0.25em 0 15px 0;
  letter-spacing: 0.12em;
  &.style2 {
    color: var(--title-color);
  }
}

.title-area {
  margin-bottom: calc(var(--section-title-space) - 20px);
}
.title-area2 {
  margin-bottom: calc(var(--section-title-space) - 0px);
}

.sec-btns {
  padding: 0px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: calc(var(--section-title-space) - 20px);

  i.fa-arrow-left {
    margin-left: 0;
    margin-right: 10px;
  }
}

.sec-line-wrap {
  display: flex;
  gap: 25px;
  align-items: center;
  margin: 0 0 80px 0;

  .sec-title2 {
    margin: -0.3em 0 -0.4em 0;
  }
}

.sec-line {
  background-color: $theme-color;
  flex: 1;
  height: 2px;
}

.border-title {
  position: relative;
  padding: 0 0 5px 0;
  margin-bottom: 0;

  &-border {
    width: 48px;
    height: 2px;
    display: inline-block;
    background-color: $theme-color;
    margin-bottom: 25px;
  }
}

@include ml {
  .sec-title4 {
    font-size: 42px;
  }
}

@include lg {
  .sec-title4 {
    font-size: 36px;
    line-height: 1.4;
  }
}

@include md {
  .sec-title3.h1,
  .sec-title.h1 {
    font-size: 36px;
  }

  .sec-line-wrap {
    margin: 0 0 60px 0;
  }

  .sec-subtitle2 {
    margin-bottom: 15px;
  }

  .title-area {
    margin-bottom: 40px;
  }
}

@include sm {
  .sec-title3.h1,
  .sec-title.h1 {
    font-size: 30px;
  }

  .sec-subtitle {
    font-size: 16px;
  }

  .sec-line-wrap {
    margin: 0 0 40px 0;
    gap: 10px;
  }

  .sec-title4 {
    font-size: 30px;
    line-height: 1.4;
  }
}
