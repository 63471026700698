.sidemenu-wrapper {
  position: fixed;
  z-index: 99999;
  right: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.8s;

  .closeButton {
    display: inline-block;
    border: none;
    color: $white-color;
    background-color: $theme-color;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    padding: 0;
    position: absolute;
    top: 15px;
    right: 15px;
    border-radius: 50%;
    transform: rotate(0);
    transition: all ease 0.4s;

    &:hover {
      background-color: $title-color;
      color: $white-color;
      transform: rotate(90deg);
    }
  }

  .sidemenu-content {
    background-color: $white-color;
    width: 450px;
    margin-left: auto;
    padding: 40px;
    height: 100%;
    overflow: scroll;
    position: relative;
    right: -500px;
    cursor: auto;
    transition-delay: 1s;
    transition: right ease 1s;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 2px;
      background-color: #f5f5f5;
    }
  }

  .widget {
    padding: 0;
    border: none;
    margin: 0 0 40px 0;
    background-color: transparent;
  }

  .widget_title {
    border-bottom: 1px solid $border-color;
    padding: 0 0 20px 0;
    margin: 0 0 25px 0;
  }

  &.show {
    opacity: 1;
    visibility: visible;
    width: 100%;
    transition: all ease 0.8s;

    .sidemenu-content {
      right: 0;
      opacity: 1;
      visibility: visible;
    }
  }
}

.offcanvas-wrapper {
  position: fixed;
  z-index: 99999;
  right: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.8s;

  .closeButton {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 20px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 20px;
    padding: 0;
    background-color: var(--theme-color);
    color: var(--white-color);
    border: none;
    border-radius: 50%;
    transform: rotate(0);
    transition: all ease 0.4s;
    z-index: 33;

    i {
      line-height: inherit;
    }

    &:hover {
      color: $title-color;
      border-color: transparent;
      transform: rotate(90deg);
      background-color: var(--theme-color2);
    }
  }

  .sidemenu-content {
    background-color: $white-color;
    width: 450px;
    margin-left: auto;
    padding: 50px 30px;
    height: 100%;
    overflow: scroll;
    position: relative;
    right: -500px;
    cursor: auto;
    transition-delay: 1s;
    transition: right ease 1s;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 2px;
      background-color: #f5f5f5;
    }
  }

  .widget {
    padding: 0;
    border: none;
    background-color: transparent;
    margin-bottom: 50px;
    .mini_cart_item {
      position: relative;
      border-bottom: 1px solid var(--vs-border-color);
      padding: 0 0 16px 0px;
      margin: 0 0 25px 0;
      min-height: 90px;
      display: flex;
      align-items: center;
      gap: 15px;
      text-align: center;
    }
  }

  .footer-text {
    max-width: 100%;
  }

  &.show {
    opacity: 1;
    visibility: visible;
    width: 100%;
    transition: all ease 0.8s;

    .sidemenu-content {
      right: 0;
      opacity: 1;
      visibility: visible;
    }
  }
}

.popup-search-box {
  position: fixed;
  top: 0;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.95);
  height: 0;
  width: 0;
  overflow: hidden;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  transform: translateX(-50%);
  transition: all ease 0.4s;
}

.popup-search-box button.searchClose {
  width: 60px;
  height: 60px;
  line-height: 60px;
  position: absolute;
  top: 40px;
  right: 40px;
  border: none;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 30px;
  border-radius: 50%;
  transition: all ease 0.4s;
}

.popup-search-box button.searchClose i {
  line-height: inherit;
}

.popup-search-box button.searchClose:hover {
  background-color: var(--theme-color2);
  color: var(--title-color);
}

.popup-search-box form {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  padding-bottom: 40px;
  cursor: auto;
  width: 100%;
  max-width: 700px;
  transform: translate(-50%, -50%) scale(0);
  transition: transform ease 0.4s;
}

@media (max-width: 1199px) {
  .popup-search-box form {
    max-width: 600px;
  }
}

.popup-search-box form input {
  font-size: 14px;
  height: 70px;
  width: 100%;
  border: 2px solid var(--theme-color);
  background-color: rgba(0, 0, 0, 0);
  padding-left: 30px;
  color: #fff;
  border-radius: 50px;
}

.popup-search-box form input::-moz-placeholder {
  color: #fff;
}

.popup-search-box form input::placeholder {
  color: #fff;
}

.popup-search-box form button {
  position: absolute;
  top: 0px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: #fff;
  font-size: 20px;
  right: 13px;
  color: var(--white-color);
  cursor: pointer;
  width: 70px;
  height: 70px;
  transition: all ease 0.4s;
  transform: scale(1.001);
}

.popup-search-box form button:hover {
  transform: scale(1.1);
}

.popup-search-box.show {
  opacity: 1;
  visibility: visible;
  width: 100.1%;
  height: 100%;
  transition: all ease 0.4s;
  border-radius: 0;
}

.popup-search-box.show form {
  transition-delay: 0.5s;
  transform: translate(-50%, -50%) scale(1);
}
