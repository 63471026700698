.product-style1,
.product-style4,
.product-style5,
.product-style6 {
  background-color: var(--white-color);
  border-radius: 30px;
  margin-bottom: 30px;
  border: 1px solid var(--white-color);
  @include smooth-transition;
  position: relative;
  .product-img {
    padding-top: 15px;
    .img {
      height: 280px;
      object-fit: contain;
    }
  }
  .product-tag1,
  .product-tag2 {
    position: absolute;
    top: 0;
  }
  .product-tag1 {
    left: auto;
    right: 20px;
    top: 20px;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    background-color: var(--theme-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color);
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
  }
  .product-tag2 {
    top: 20px;
    left: 20px;
    font-size: 12px;
    font-weight: 600;
    background-color: var(--theme-color);
    color: var(--white-color);
    padding: 1px 14px;
    font-family: var(--title-font);
    border-radius: 15px;
    border: 1px solid #299310;
  }
  .product-content {
    position: relative;
    padding: 0 30px 30px 30px;
    @include respond-below(lg) {
      padding: 0 15px 15px 15px;
    }
  }
  .product-actions {
    display: flex;
    position: absolute;
    right: 30px;
    bottom: 25px;
    align-items: center;
  }
  .cart-btn {
    width: 47px;
    height: 47px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-color2);
    font-size: 20px;
    color: var(--title-color);
    border-radius: 50%;
    position: relative;
    z-index: 1;
  }
  .vs-btn {
    font-size: 14px;
    padding: 11px 59px 11px 22px;
    margin-right: -47px;
    transform: scaleX(0);
    transform-origin: right;
    opacity: 0;
    visibility: hidden;
  }
  .product-title {
    font-size: 18px;
    margin-bottom: 10px;
    padding-right: 15px;
    a {
      color: var(--title-color);
      &:hover {
        color: var(--theme-color);
      }
    }
  }
  .product-cate {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color);
    margin-bottom: 5px;
  }
  .product-price {
    font-size: 24px;
    font-weight: 800;
  }
  .star-rating {
    font-size: 10px;
    margin-bottom: 13px;
  }
  &:hover {
    border: 1px solid var(--theme-color2);
    box-shadow: 0px 21px 10px 0px rgba(4, 4, 4, 0.05);
    .vs-btn {
      opacity: 1;
      visibility: visible;
      transform: scaleX(1);
    }
  }
}

.product-style1 {
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid transparent;
  &:hover {
    border: 1px solid var(--theme-color2);
  }
}

.product-style4 {
  text-align: center;
  border-radius: 0;
  border: 0;
  margin-bottom: 17px;
  @include respond-below(md) {
    padding: 30px;
    border-radius: 20px;
    margin-bottom: 30px;
  }
  .product-content {
    padding: 0 40px 0px 40px;
    @include respond-below(xl) {
      padding: 0 10px;
    }
  }
  .product-price {
    display: block;
    margin-bottom: 10px;
  }
  .star-rating {
    margin: 0 auto 13px;
  }
  .product-actions {
    position: static;
    margin-left: 0px;
    @include smooth-transition;
    justify-content: center;
    .vs-btn {
      display: none;
    }
    .cart-btn {
      &:hover {
        background-color: var(--theme-color);
        color: var(--white-color);
      }
    }
  }
  &:hover {
    border: 0;
    box-shadow: none;
    .product-actions {
      margin-left: 0;
    }
  }
}
.product-col {
  border-right: 1px solid var(--vs-border-color);
  @include respond-below(md) {
    border: 0;
  }
  &:last-child {
    border-right: 0;
  }
}

.product-layout2 {
  background-color: var(--bg-color1);
  padding: 120px 0 90px;
  @include respond-below(sm) {
    padding: 80px 0 50px;
  }
  .slick-dots button {
    background-color: var(--white-color);
  }
}

.product-carousel {
  padding-top: 40px;
  .slick-dots {
    margin: 40px 0 90px 0;
  }
}

.product-features {
  border-top: 1px solid var(--vs-border-color2);
  padding: 100px 40px 0 40px;
  @include respond-below(sm) {
    padding: 50px 0 0;
  }
}

.product-style2 {
  .counter-title {
    font-weight: 600;
    background-color: var(--theme-color2);
    border-radius: 13.5px;
    font-size: 14px;
    padding: 1px 20px;
    display: block;
    max-width: 45%;
    text-align: center;
    margin-bottom: -14px;
    margin-left: 22px;
    @include respond-below(xl) {
      max-width: 53%;
    }
    @include respond-below(sm) {
      max-width: 100%;
      margin-left: 0;
      margin-bottom: 0;
    }
  }
  .product-tax {
    display: block;
  }
  .product-text {
    margin-bottom: 5px;
    color: var(--white-color);
  }
  .product-title {
    color: var(--white-color);
    font-size: 30px;
    font-weight: 800;
    margin-bottom: 20px;
  }
  .star-rating {
    margin-bottom: 24px;
  }
  .product-price {
    font-size: 30px;
    font-weight: 800;
    color: var(--white-color);
    display: flex;
    align-items: center;
    gap: 10px;
    del {
      font-size: 19px;
    }
  }
  .product-tax {
    font-size: 15px;
    color: var(--white-color);
    display: block;
    margin-bottom: 40px;
  }
  .product-image {
    position: relative;
    z-index: 1;
    @include respond-below(md) {
      text-align: center;
    }
    .product-circle {
      width: 411.52px;
      height: 408.01px;
      position: absolute;
      bottom: 0;
      background-color: var(--theme-color2);
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      @include respond-below(lg) {
        width: 321.52px;
        height: 321.52px;
      }
      @include respond-below(md) {
        width: 411.52px;
        height: 408.01px;
      }
      @include respond-below(md) {
        width: 300px;
        height: 300px;
      }
    }
  }
}

.item-style {
  display: flex;
  align-items: center;
  gap: 23px;
  max-width: 250px;
  .item-icon {
    flex: none;
    position: relative;
    z-index: 1;
    &::before {
      position: absolute;
      content: "";
      width: 40px;
      height: 40px;
      background-color: var(--bg-color2);
      z-index: -1;
      border-radius: 50%;
      right: -7px;
      top: -7px;
    }
  }
  .item-title {
    font-family: var(--body-font);
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 0;
    color: var(--white-color);
    max-width: 152px;
  }
}

.buying-options {
  .buying-title {
    color: var(--white-color);
    font-size: 24px;
    font-weight: 800;
    padding-bottom: 5px;
    position: relative;
    text-transform: uppercase;
    font-family: var(--body-font);
    margin-bottom: 50px;
    &::before {
      content: "";
      width: 66px;
      height: 3px;
      background-color: var(--theme-color2);
      display: inline-flex;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .form-check {
    padding-left: 0;
    margin-bottom: 25px;
    .form-check-label {
      color: var(--white-color);
      font-weight: 400;
      &::before {
        top: -3px;
        line-height: 14px;
        text-align: center;
        border: 3px solid var(--theme-color);
        background-color: transparent;
        font-size: 0em;
      }
    }
    input[type="radio"]:checked ~ label::before {
      background-color: var(--theme-color);
      font-size: 0.5em;
    }
    &:last-of-type {
      margin-bottom: 45px;
    }
  }
}

.product-style3 {
  display: flex;
  gap: 22px;
  align-items: stretch;
  background-color: var(--white-color);
  border-radius: 30px;
  @include respond-below(xl) {
    gap: 10px;
  }
  @include respond-below(sm) {
    flex-direction: column;
  }
  .product-img {
    flex: none;
    min-width: 300px;
    padding-left: 45px;
    position: relative;
    z-index: 1;
    @include respond-below(xl) {
      padding-left: 0;
      min-width: 220px;
    }
    @include respond-below(lg) {
      min-width: 180px;
    }
    @include respond-below(sm) {
      text-align: center;
    }
    img {
      height: 100%;
      object-fit: contain;
      @include respond-below(xl) {
        max-width: 220px;
      }
      @include respond-below(lg) {
        max-width: 180px;
      }
    }
    &::before {
      position: absolute;
      content: "";
      width: 182px;
      height: 100%;
      background-color: var(--theme-color2);
      z-index: -1;
      left: 0;
      border-radius: 30px;
      @include respond-below(lg) {
        width: 100px;
      }
    }
  }
  .product-title {
    font-size: 22px;
    margin: 0 0 10px 0;
    @include respond-below(lg) {
      font-size: 20px;
    }
    a {
      color: var(--title-color);
      display: block;
      &:hover {
        color: var(--theme-color);
      }
    }
  }
  .product-cate {
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    font-size: 14px;
    text-decoration: underline;
  }
  .product-weight {
    display: block;
    font-size: 14px;
    font-weight: 500;
  }
  .product-price {
    font-size: 28px;
    font-weight: 800;
    display: block;
    padding: 8px 0;
  }
  .product-actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .star-rating {
    font-size: 11px;
    margin-bottom: 20px;
    &::before {
      letter-spacing: 6px;
    }
    span {
      &::before {
        letter-spacing: 6px;
      }
    }
  }
  .product-content {
    padding: 35px 35px 35px 0;
    align-self: center;
    @include respond-below(sm) {
      padding: 15px;
    }
  }
  .vs-btn {
    padding: 17px 38px;
  }
  .heart-btn {
    height: 50px;
    min-width: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--vs-border-color);
    border-radius: 50%;
    color: var(--title-color);
    &:hover {
      background-color: var(--theme-color2);
      color: var(--title-color);
      border-color: var(--theme-color2);
    }
  }
}

.product-tab-menu {
  margin-bottom: -30px;
  .nav-tabs {
    border-bottom: 0;
    justify-content: center;
    gap: 27px;
    .nav-link {
      display: flex;
      align-items: center;
      gap: 17px;
      background-color: var(--white-color);
      border-radius: 30px;
      padding: 3px 17px 3px 3px;
      font-size: 16px;
      font-weight: 700;
      @include smooth-transition;
      margin-bottom: 0;
      border: 1px solid var(--vs-border-color3);
      span {
        min-width: 52px;
        height: 52px;
        display: inline-flex;
        align-items: center;
        background-color: var(--smoke-color);
        justify-content: center;
        border-radius: 50%;
        @include smooth-transition;
      }
      &:hover,
      &.active {
        background-color: var(--theme-color);
        color: var(--white-color);
        border: 1px solid var(--theme-color);
        span {
          background-color: var(--white-color);
        }
      }
    }
  }
}

.product-tab-body {
  background-color: var(--white-color);
  box-shadow: 0px 21px 10px 0px rgba(4, 4, 4, 0.05);
  padding: 78px 0 41px 0;
  border-radius: 30px;
  @include respond-below(md) {
    background-color: transparent;
    box-shadow: none;
    padding-bottom: 0;
  }
}

.product-style5 {
  text-align: center;
  border: 1px solid var(--vs-border-color);
  border-radius: 30px;
  margin-bottom: 52px;
  .product-title {
    padding: 0 20px 20px;
    border-bottom: 1px solid var(--vs-border-color3);
    margin-bottom: 20px;
  }
  .product-cate {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color);
    margin-bottom: 10px;
  }
  .star-rating {
    width: auto;
    &::before,
    span::before {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .product-actions2 {
    display: inline-flex;
  }
  .product-content {
    padding: 0 30px 0px 30px;
    margin-bottom: -22px;
  }
  .product-price {
    margin-bottom: 15px;
    display: block;
  }
  .vs-btn {
    transform: scaleX(1);
    opacity: 1;
    visibility: visible;
    padding: 0 25px 0 0;
    margin: 0;
    background-color: var(--white-color);
    color: var(--title-color);
    border: 2px solid var(--theme-color2);
    font-size: 16px;
    font-weight: 700;
    i {
      margin-left: 0px;
      background-color: var(--theme-color2);
      margin-right: 15px;
      color: var(--title-color);
      transform: translateX(-2px);
      width: 42px;
    }
    &:hover {
      background-color: var(--theme-color);
      color: var(--white-color);
      border-color: var(--theme-color);
      &::before,
      &::after {
        background-color: var(--theme-color);
      }
      i {
        background-color: var(--white-color);
        color: var(--theme-color);
      }
    }
  }
}
.product-style1,
.product-style6 {
  .product-title {
    padding-right: 20px;
  }
}
.product-style6 {
  border: 1px solid var(--vs-border-color4);
}

.product-style7 {
  display: flex;
  align-items: stretch;
  border: 1px solid var(--vs-border-color3);
  border-radius: 30px;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  .star-rating {
    margin-bottom: 15px;
  }
  .product-weight {
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .product-price {
    font-size: 16px;
    font-weight: 700;
    del {
      font-size: 18px;
      color: var(--text-color);
      padding-left: 5px;
    }
  }
  .product-img {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    border: 1px solid var(--vs-border-color3);
    border-radius: 30px;
    overflow: hidden;
    margin: -1px;
    @include respond-below(sm) {
      flex: auto;
      border-radius: 30px 30px 0 0;
    }
    a {
      height: 100%;
    }
    img {
      height: 100%;
      object-fit: contain;
      @include respond-below(lg) {
        max-width: 167px;
      }
      @include respond-below(sm) {
        max-width: 100%;
      }
    }
  }
  .product-content {
    padding: 20px 60px 20px 40px;
    flex: 1;
    align-self: center;
    @include respond-below(lg) {
      padding: 20px 20px 20px 20px;
    }
  }
  .product-title {
    font-size: 18px;
    margin: -0.14em 0 10px 0;
    a {
      color: var(--title-color);
      &:hover {
        color: var(--theme-color);
      }
    }
  }
  .cart-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    --icon-size: 47px;
    min-width: var(--icon-size);
    min-height: var(--icon-size);
    background-color: var(--theme-color2);
    width: var(--icon-size);
    height: var(--icon-size);
    color: var(--title-color);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    &:hover {
      background-color: var(--theme-color);
      color: var(--white-color);
    }
  }
}

.product-style8 {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  .product-title {
    font-size: 18px;
    margin-bottom: 10px;
    a {
      color: var(--title-color);
      &:hover {
        color: var(--theme-color);
      }
    }
  }
  .product-img {
    position: relative;
    overflow: hidden;
    border-radius: 30px;
    margin-bottom: 16px;
  }
  .cart-btn {
    width: 47px;
    height: 47px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-color2);
    font-size: 20px;
    color: var(--title-color);
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    bottom: 10px;
    right: 10px;
  }
  .star-rating {
    margin-bottom: 10px;
  }
  .product-price {
    font-size: 24px;
    font-weight: 800;
  }
}

.product-about {
  padding-left: 35px;
  @include respond-below(lg) {
    padding-left: 0;
  }
  .product-rating {
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;

    @include respond-below(sm) {
      flex-wrap: wrap;
      gap: 10px;
    }

    &__total {
      color: var(--text-color);
      font-size: 14px;
      font-weight: 700;
    }
  }

  .star-rating {
    margin-right: 10px;
    font-size: 14px;
    width: 109px;

    &::before,
    span::before {
      color: var(--rating-color);
      transform: translate(0%, 0%);
      font-weight: 700;
      top: 0;
      left: 0;
      position: absolute;
      display: block;
      letter-spacing: 7px;
    }
  }

  .product-title {
    font-size: 38px;
    margin-bottom: 25px;
    font-weight: 700;
    line-height: 1.2;
    max-width: 88%;

    @include lg {
      max-width: 100%;
    }

    @include respond-below(sm) {
      font-size: 28px;
      margin-bottom: 25px;
    }
  }

  .product-ml {
    font-size: 18px;
    font-weight: 500;
    display: block;
    margin-bottom: 20px;
    color: var(--text-color);
  }

  .qty-input {
    border: 0;
    padding: 0 25px;
    min-height: 54px;
    font-size: 16px;
    font-weight: 600;
    color: var(--black-color);
  }

  .product-instock {
    display: inline-flex;
    align-items: center;
    color: #1f9d25;
    font-size: 18px;
    font-weight: 700;
    margin-right: 15px;
    gap: 10px;
    line-height: 1;
    padding-top: 4px;
  }

  .product-price {
    font-size: 40px;
    font-weight: 700;
    color: var(--black-color);
    line-height: 1;
    margin: 0 0 40px 0;

    del {
      font-size: 20px;
      color: var(--text-color);
      font-weight: 700;
    }
  }

  .product-text {
    margin-bottom: 24px;
  }

  .actions {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 20px;
    margin-bottom: 35px;
    align-items: center;
  }
  .pro-btns {
    display: flex;
    align-items: center;
    gap: 14px;
    padding-bottom: 35px;
    border-bottom: 1px solid var(--vs-border-color9);
    margin-bottom: 35px;
  }

  .vs-btn {
    padding: 19px 70px;

    &::after,
    &::before {
      background-color: var(--theme-color2);
    }
  }

  .icon-btn {
    background-color: transparent;
    color: var(--black-color);
    border: 1px solid var(--vs-border-color);
    font-size: 20px;
    width: var(--btn-size, 54px);
    height: var(--btn-size, 54px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &::before {
      background-color: var(--theme-color);
      --border-size: 0;
    }

    &:hover {
      border-color: var(--theme-color2);
      color: var(--title-color);
      background-color: var(--theme-color2);
    }
  }

  .product-getway {
    padding-top: 30px;
  }

  .getway-title {
    font-size: 14px;
    color: var(--black-color);
    font-weight: 700;
    margin: -0.15em 0 15px 0;
    line-height: 1;
    font-family: var(--body-font);
    text-transform: uppercase;
    letter-spacing: 0.05em;
    display: block;
  }
  .counter-title {
    font-weight: 600;
    background-color: var(--theme-color2);
    border-radius: 13.5px;
    font-size: 14px;
    padding: 1px 14px;
    display: block;
    text-align: center;
    margin-bottom: -14px;
    margin-left: 22px;
    max-width: 161px;
    z-index: 1;
    position: relative;
  }
  .counter-style {
    display: inline-flex;
    background-color: var(--smoke-color);
    border: 0;
    margin-bottom: 35px;
    .count-number,
    .count-name {
      color: var(--title-color);
    }
    .offer-counter > li {
      padding: 30px 30px 20px 30px;
      @include respond-below(sm) {
        padding: 0;
      }
    }
    .offer-counter {
      min-height: 103px;
      @include respond-below(sm) {
        gap: 20px;
        padding: 20px;
      }
    }
  }
}

.product-big-img {
  background-color: var(--bg4-color);
  border: 1px solid var(--theme-color2);
  margin-bottom: 25px;
  text-align: center;
  border-radius: 30px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.product-thumb-slide {
  @include respond-below(sm) {
    max-width: 100%;
  }

  &.row {
    --bs-gutter-x: 20px;
  }

  .thumb {
    background-color: var(--bg4-color);
    overflow: hidden;
    transition: all ease 0.4s;
    border: 1px solid var(--vs-border-color8);
    cursor: pointer;
    border-radius: 20px;
    margin-bottom: 0px;
    margin-right: 15px;

    @include respond-below(sm) {
      margin-bottom: 0;
      margin: 0 7.5px;
    }

    img {
      transition: all ease 0.4s;
      width: 100%;
    }

    &:hover {
      border-color: $theme-color;
    }
  }

  .slick-current.slick-active .thumb {
    border-color: $theme-color2;
  }
}

.qty-btn {
  background: transparent;
  border: 0;
  padding: 0;
  line-height: 1;
  display: inline-flex;
  margin: -4px 0;
  color: var(--text-color);
}

.quantity {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 21px;
  flex-wrap: wrap;
  .screen-reader-text {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 700;
    font-family: var(--title-font);
    color: var(--text-color);
  }
  &__field {
    display: flex;
    align-items: center;
    border: 2px solid var(--theme-color2);
    min-width: 144px;
    padding-right: 25px;
    border-radius: 10px;
    overflow: hidden;
    @include respond-below(md) {
      min-width: 130px;
    }
  }
  &__buttons {
    display: inline-flex;
    flex-direction: column;
    gap: 0px;
  }
}

.product_meta {
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;

  > span {
    display: flex;
    margin-bottom: 10px;
    color: var(--theme-color);
    font-weight: 700;
    @include respond-below(sm) {
      flex-wrap: wrap;
    }

    & > p {
      margin-bottom: 0;
      color: var(--theme-color);
    }

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: inherit;

      &:hover {
        color: var(--theme-color);
      }
    }

    > a,
    > span {
      position: relative;
      margin-left: 3px;
      color: var(--text-color);
      text-transform: capitalize;
      font-weight: 700;

      &:first-child {
        margin-left: 5px;
      }

      &:not(:last-child)::after {
        content: ",";
        margin-right: 3px;
      }
    }
  }
}

.pros,
.cons {
  background: #edffd9;
  padding: 50px;
  border-radius: 30px;
  @include respond-below(md) {
    padding: 15px;
  }
  .list-style4 {
    margin-bottom: 0;
    ul {
      li {
        margin-bottom: 15px;
      }
    }
  }
}

.cons {
  background-color: #fff1e0;
}

.product-description {
  p {
    font-weight: 500;
    line-height: 32px;
    padding-right: 10px;
  }
}
.product-information {
  &__item {
    background-color: #f5f5f5;
    min-height: 66px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    font-family: var(--title-font);
    color: var(--black-color);
    margin-bottom: 10px;
    border-radius: 999px;
    span {
      color: var(--text-color);
      font-size: 18px;
    }
    @include respond-below(sm) {
      font-size: 16px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__name {
    color: var(--text-color);
    background-color: var(--white-color);
    min-height: 66px;
    border: 1px solid var(--vs-border-color10);
    display: inline-flex;
    align-items: center;
    min-width: 310px;
    padding: 10px 47px;
    margin-right: 25px;
    border-radius: 999px;
    @include respond-below(md) {
      min-width: 180px;
      padding: 10px 15px;
      margin-right: 15px;
    }
    @include respond-below(sm) {
      min-width: 140px;
    }
  }
}

.product-layout2 {
  .ele1,
  .ele2 {
    position: absolute;
  }

  .ele1 {
    top: 123px;
    left: 69px;
  }
  .ele2 {
    bottom: 345px;
    right: 100px;
  }
}

.product-slider1 {
  .slick-slide.slick-current.slick-active {
    .product-img {
      &::before {
        background-color: var(--theme-color);
      }
    }
  }
}

.product-layout3i {
  .ele1 {
    position: absolute;
    right: 0;
    top: 25%;
  }
}
