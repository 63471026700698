.step-item {
  margin-bottom: 30px;
  .step-img {
    position: relative;
    display: inline-flex;
    padding: 10px;
    border: 3px dashed var(--theme-color2);
    border-radius: 50%;
    margin-bottom: 30px;
  }
  .step-number {
    position: absolute;
    bottom: 5px;
    left: 5px;
    min-width: 60px;
    min-height: 60px;
    background-color: var(--theme-color2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 800;
    border-radius: 50%;
    padding: 5px;
  }
  .step-title {
    font-size: 26px;
    font-weight: 800;
    margin-bottom: 15px;
  }
  .step-text {
    line-height: 30px;
    margin-bottom: 0;
    padding-right: 15px;
  }
}
