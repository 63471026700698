.testi-style1 {
  padding: 50px 50px 45px 50px;
  border: 2px solid var(--vs-border-color3);
  border-radius: 30px;
  position: relative;
  margin-bottom: 13px;
  background-color: var(--white-color);
  @include respond-below(lg) {
    padding: 20px;
  }
  @include respond-below(sm) {
    text-align: center;
  }
  &::before {
    position: absolute;
    content: "";
    width: 560px;
    height: 328px;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -15px;
    border: 2px solid var(--vs-border-color3);
    border-radius: 30px;
    z-index: -1;
    @include smooth-transition;
    @include respond-below(xl) {
      width: 510px;
    }
    @include respond-below(lg) {
      width: 400px;
    }
    @include respond-below(sm) {
      width: 250px;
    }
  }
  .star-rating {
    width: 114px;
    font-size: 15px;
    margin-bottom: 10px;
    &::before {
      letter-spacing: 7px;
    }
    span {
      &::before {
        letter-spacing: 7px;
      }
    }
  }
  .testi-author {
    color: var(--text-color);
    display: inline-block;
    margin-bottom: 5px;
    a {
      font-weight: 700;
      color: var(--text-color);
    }
  }
  .testi-image {
    min-width: 140px;
    max-height: 140px;
    display: inline-flex;
    position: relative;
    @include respond-below(lg) {
      min-width: 120px;
      max-height: 120px;
    }
    .img1 {
      min-width: 140px;
      max-height: 140px;
      border-radius: 50%;
      box-shadow: 0px 21px 10px 0px rgba(4, 4, 4, 0.05);
      @include respond-below(lg) {
        min-width: 120px;
        max-height: 120px;
      }
    }
  }
  .testi-icon {
    position: absolute;
    top: 0;
    right: -8px;
    width: 54px;
    height: 54px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-color);
    border-radius: 50%;
  }
  .testi-content {
    display: flex;
    gap: 40px;
    @include respond-below(sm) {
      gap: 15px;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
    }
  }
  .testi-text {
    margin-bottom: 0;
    line-height: 30px;
  }
  &:hover {
    &::before {
      background-color: var(--theme-color);
      border-color: var(--theme-color);
    }
  }
}

.testimonials-layout2 {
  background-color: var(--bg2-color);
  .slick-dots {
    margin: 75px 0 24px 0;
  }
}
.testi-style2 {
  background-color: var(--white-color);
  border-radius: 30px;
  padding: 50px 50px 0 50px;
  margin-bottom: 33px;
  @include respond-below(lg) {
    padding: 15px 15px 0 15px;
  }
  .testi-title {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .testi-author {
    color: var(--title-color);
    a {
      color: var(--title-color);
      font-weight: 700;
      text-decoration: underline;
    }
  }
  .star-rating {
    margin-bottom: 10px;
  }
  .testi-footer {
    display: flex;
    justify-content: space-between;
  }
  .testi-ratings {
    flex: none;
  }
  .testi-image {
    margin-bottom: -33px;
    border-radius: 33px;
    overflow: hidden;
    border: 1px solid var(--white-color);
    margin-right: -20px;
    @include respond-below(lg) {
      max-width: 100px;
    }
  }
  .testi-text {
    margin: 0 26px 45px 0;
    line-height: 30px;
  }
}

.testi-ele1,
.testi-ele2,
.testi-ele3 {
  position: absolute;
}

.testi-ele1 {
  top: 424px;
  left: 0;
}
.testi-ele2 {
  left: 91px;
  bottom: 237px;
}
.testi-ele3 {
  left: auto;
  top: 268px;
  right: 39px;
}
