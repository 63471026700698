.vs-sort-bar {
  margin-bottom: 35px;
  select {
    height: 50px;
    border: 1px solid #eee;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    min-width: auto;
    padding: 0 45px 0 25px;
    font-size: 16px;
    margin: 0;
    background-position: right 22px center;
  }
  label {
    font-size: 14px;
    margin-bottom: 0;
    margin-right: 15px;
    font-weight: 500;
    line-height: 1;
  }
  p.woocommerce-result-count {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 18px;
    span {
      color: var(--theme-color);
    }
  }
  .nice-select {
    width: 100%;
    min-width: 250px;
    padding-right: 50px;
    padding-left: 26px;
    height: 60px;
    line-height: 64px;
    border-radius: 40px;
    font-size: 16px;
    font-family: var(--body-font);
    border: 1px solid var(--vs-border-color4);
    .current {
      font-weight: 700;
    }
    .list {
      width: 100%;
      border-radius: 0;
      margin-top: 0px;
      border: 1px solid var(--vs-border-color4);
      box-shadow: none;
      z-index: 2;
      border-radius: 30px;
    }

    &::before {
      content: "";
      width: 36px;
      height: 36px;
      background: var(--theme-color2);
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
    }
    &::after {
      border-bottom: 2px solid var(--title-color);
      border-right: 2px solid var(--title-color);
      height: 8px;
      margin-top: -4px;
      right: 25px;
      width: 8px;
    }

    @include sm {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .nav-tabs {
    border-bottom: 0;
    gap: 5px;
  }
  .nav-link {
    --icon-size: 60px;
    min-width: var(--icon-size);
    min-height: var(--icon-size);
    width: var(--icon-size);
    height: var(--icon-size);
    background-color: transparent;
    border: 1px solid var(--vs-border-color4);
    border-radius: 50%;
    @include smooth-transition;
    padding: 5px;
    font-size: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: var(--theme-color2);
      color: var(--title-color);
      border-color: var(--theme-color2);
    }
    &.active {
      border-color: var(--theme-color2);
      background-color: var(--theme-color2);
      color: var(--title-color);
      &:hover {
        background-color: var(--theme-color2);
        color: var(--title-color);
        border-color: var(--theme-color2);
      }
    }
  }
}
