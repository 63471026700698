/*------------------- 4.1. Widget  -------------------*/
.widget {
  padding: var(--widget-padding-y, 40px) var(--widget-padding-x, 40px);
  background-color: var(--smoke-color);
  position: relative;
  margin-bottom: 30px;
  border-radius: 30px;
  border: 1px solid var(--vs-border-color5);
  &:last-child {
    margin-bottom: 0;
  }

  select,
  input {
    height: 55px;
    border: none;
    background-color: #fff;
    padding-left: 20px;
    font-weight: 400;
  }
}

.widget_title {
  position: relative;
  font-size: 26px;
  font-weight: 700;
  line-height: 1em;
  margin: -0.07em 0 30px 0;
  font-family: var(--title-font);
  padding: 0 0 10px 0;
  &::after {
    position: absolute;
    content: "";
    width: 40px;
    height: 4px;
    background-color: var(--theme-color);
    bottom: 0;
    left: 0;
  }
}

.widget {
  .search-form {
    position: relative;
    display: flex;
    background-color: var(--white-color);
    border-radius: 9999px;
    overflow: hidden;
    padding: 9px;
    border: 1px solid var(--vs-border-color5);

    input {
      flex: 1;
      height: auto;
    }

    button {
      border: none;
      background-color: var(--theme-color);
      color: var(--white-color);
      height: 49px;
      width: 49px;
      line-height: 1;
      font-size: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      &:hover {
        background-color: var(--theme-color2);
        color: $title-color;
      }
    }
  }
}

.wp-block-tag-cloud,
.tagcloud {
  a {
    display: inline-block;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    padding: 9.5px 16.5px;
    margin-right: 5px;
    margin-bottom: 10px;
    color: $title-color;
    background-color: $smoke-color;
    border-radius: 4px;

    &:hover {
      background-color: $theme-color;
      color: $white-color;
    }
  }
}

.tagcloud {
  margin-right: -5px;
  margin-bottom: -10px;

  a {
    background-color: $white-color;
    color: $title-color;
  }
}

.recent-post {
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  &:last-child {
    margin-bottom: 0;
  }

  .media-img {
    --icon-size: 100px;
    margin-right: 20px;
    width: var(--icon-size);
    height: var(--icon-size);
    overflow: hidden;
    border-radius: 10px;

    img {
      width: 100%;
      border-radius: 4px;
      transition: all ease 0.4s;
    }
  }

  .post-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 10px 0;
    padding-right: 20px;
  }

  .recent-post-meta {
    a {
      text-transform: capitalize;
      font-size: 15px;
      font-weight: 700;
      color: var(--text-color);

      i {
        margin-right: 5px;
        color: $theme-color;
      }
    }
  }

  &:hover {
    .media-img {
      img {
        transform: scale(1.1);
      }
    }
  }
}

.wp-block-calendar,
.calendar_wrap {
  position: relative;
  background-color: #fff;
  padding-bottom: 0;
  border: none;

  span[class*="wp-calendar-nav"] {
    position: absolute;
    top: 8px;
    left: 20px;
    font-size: 14px;
    color: $title-color;
    font-weight: 500;
    z-index: 1;

    a {
      color: inherit;
    }
  }

  span.wp-calendar-nav-next {
    left: auto;
    right: 20px;
  }

  caption {
    caption-side: top;
    text-align: center;
    color: $title-color;
    background-color: #f1dbbe;
  }

  th {
    font-size: 14px;
    padding: 5px 5px;
    border: none;
    text-align: center;
    border-right: 1px solid #fff;
    color: #01133c;
    font-weight: 500;

    a {
      color: inherit;
    }
  }

  td {
    font-size: 14px;
    padding: 5px;
    color: #01133c;
    border: 1px solid #ededed;
    text-align: center;
    background-color: transparent;
    transition: all ease 0.4s;
  }

  #today {
    color: $theme-color;
    background-color: $white-color;
    border-color: #ededed;
  }

  thead {
    background-color: #f6f6f6;
  }

  .wp-calendar-table {
    margin-bottom: 0;
  }

  .wp-calendar-nav {
    .pad {
      display: none;
    }
  }
}

.sidebar-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  .gallery-thumb {
    overflow: hidden;
    border-radius: 5px;

    img {
      width: 100%;
      transform: scale(1);
      transition: all ease 0.4s;
    }

    &:hover {
      img {
        transform: scale(1.2);
      }
    }
  }
}

.widget_shopping_cart {
  text-align: left;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .mini_cart_item {
    position: relative;
    border-bottom: 1px solid $border-color;
    padding: 0 0 32px 110px;
    margin: 0 0 27px 0;
    min-height: 90px;
  }

  .remove {
    position: absolute;
    right: 0;
    top: 0;
    color: $title-color;
    line-height: 1;
    font-size: 18px;

    &:hover {
      color: $theme-color;
    }
  }

  .img {
    position: absolute;
    left: 0;
    top: 3px;
    width: 90px;
    height: 90px;
    display: inline-block;
    border: 1px solid $border-color;
  }

  .product-title {
    font-size: 14px;
    color: $title-color;
    font-weight: 400;
    margin-bottom: 0;
    display: inline-block;
  }

  .amount {
    display: block;
    font-weight: 600;
    color: $title-color;
    font-size: 16px;
  }

  .quantity {
    display: inline-flex;
    margin-top: 12px;
  }

  .qut-btn {
    border: 1px solid $border-color;
    background-color: transparent;
    display: inline-block;
    background-color: transparent;
    width: 25px;
    height: 25px;
    padding: 0;
    font-size: 12px;
    z-index: 1;
    position: relative;

    &:hover {
      background-color: $title-color;
      border-color: transparent;
      color: $white-color;
    }
  }

  .qty-input {
    border: 1px solid $border-color;
    text-align: center;
    width: max-content;
    min-width: 40px;
    font-size: 12px;
    padding: 0;
    height: 25px;
    margin: 0 -1px;
  }

  .subtotal {
    float: right;
    text-align: right;
    font-size: 12px;
    margin-top: 19px;

    > span:not(.amount) {
      color: $body-color;
      font-weight: 300;
    }

    .amount {
      font-size: 12px;
      display: inline-block;
    }
  }

  .total {
    color: $title-color;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 25px;
    font-size: 18px;

    .amount {
      font-size: inherit;
      display: inline-block;
      color: $theme-color;
    }
  }
  .buttons {
    margin: 0;
    gap: 15px;
    flex-wrap: wrap;
  }
}

.sidebar-area {
  margin-bottom: 30px;
  position: sticky;
  top: 30px;

  ul.wp-block-latest-posts {
    margin-bottom: 0;

    li {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .newsletter-form {
    button {
      text-transform: capitalize;
      margin-top: 15px;
      padding: 22px 47px;
      font-family: var(--body-font);
    }
  }

  .widget .wp-block-search {
    margin-bottom: 0;
  }

  .wp-block-group__inner-container h2 {
    font-size: 20px;
    line-height: 1em;
    margin-bottom: 20px;
    margin-top: -0.07em;
  }

  ol.wp-block-latest-comments {
    padding: 0;
    margin: 0;

    li {
      line-height: 1.5;
      margin: 0 0 20px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
}

.recent-product {
  display: flex;

  .product-title {
    font-size: 16px;
  }
}

.widget-workhours {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    background-color: $white-color;
    padding: 14.5px 20px;
    margin: 0 0 15px 0;
    font-family: $title-font;
    font-weight: 500;

    &:last-child {
      margin-bottom: 0;
    }

    i {
      margin-right: 10px;
    }
  }
}

.quote-box {
  position: relative;
  text-align: center;
  padding: 80px 30px;
  margin: 0 0 30px 0;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#071a3e, 0.7);
  }

  &__title {
    color: $white-color;
    position: relative;
    z-index: 1;
    max-width: 200px;
    margin: -0.3em auto 25px auto;
  }
}

@include lg {
  .widget {
    --widget-padding-y: 30px;
    --widget-padding-x: 30px;
  }

  .widget_title {
    font-size: 20px;
    margin-bottom: 25px;
  }

  .recent-post {
    .post-title {
      font-size: 14px;
      line-height: 22px;
    }

    .recent-post-meta a {
      font-size: 12px;
    }
  }

  .widget-workhours {
    li {
      padding: 14.5px 20px;
      font-size: 14px;
    }
  }
}

@include md {
  .sidebar-area {
    padding-top: 30px;
  }

  .wp-block-tag-cloud a,
  .tagcloud a {
    padding: 10.5px 18px;
  }
}

@include sm {
  .contact-widget {
    .icon {
      --icon-size: 30px;
      --icon-font-size: 12px;
      top: 5px;
    }

    .contact {
      padding: 0 0 0 45px;
      margin: 0 0 20px 0;
      border-bottom: none;
    }
  }

  .sidebar-gallery {
    gap: 10px;
  }
}

@include xs {
  .widget {
    padding: 30px 20px;
  }
}

.widget {
  &_categories {
    padding: 0;
    margin-bottom: 0;
    li {
      margin: 0 0 3px 0;
      &:last-child {
        margin: 0 0 0px 0;
      }
      a {
        background-color: var(--white-color);
        padding: 5px 5px 5px 30px;
        margin: 0 0 0px 0;
        border-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 30px;
        font-weight: 700;
        color: var(--text-color);
        font-size: 16px;
        font-family: var(--title-color);
        &::before {
          display: none;
        }
        span {
          min-width: 50px;
          height: 50px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: var(--theme-color2);
          color: var(--text-color);
          border-radius: 50%;
          position: relative;
          transition: all 0.3s ease-in-out;
          &::before {
            content: "\f061";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: var(--icon-font);
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s ease-in-out;
          }
        }
        &:hover {
          background-color: var(--theme-color);
          color: var(--white-color);
          span {
            background-color: var(--theme-color2);
            color: var(--title-color);
          }
        }
      }
    }
  }
  &_links {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        display: block;
        position: relative;
        color: var(--text-color);
        padding-left: 15px;
        font-family: var(--body-font);
        &::before {
          content: "\f105";
          font-family: var(--icon-font);
          position: absolute;
          left: 0;
          top: 4px;
          line-height: 1;
        }
        &:hover {
          color: var(--theme-color);
        }
      }
    }
    &.style2 {
      li {
        a {
          padding-left: 0;
          padding-bottom: 10px;
          border-bottom: 1px solid var(--bg4-color);
          &::before {
            display: none;
          }
        }
        &:last-child {
          a {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: 0;
          }
        }
      }
    }
  }
  &.form {
    background-color: var(--bg3-color);
  }
  .newsletter-form {
    background-color: transparent;
    padding: 0;
    .form-control {
      min-width: 100%;
      background-color: var(--white-color);
    }
    .newsletter-text {
      color: var(--text-color2);
    }
    .vs-btn {
      &:hover {
        color: var(--title-color);
      }
    }
  }
}

.sidebar-area {
  &.product {
    background-color: var(--smoke-color);
    padding: 30px;
    border-radius: 30px;
    margin-bottom: 30px;
    @include respond-below(sm) {
      padding: 15px;
    }
  }
}

.widget {
  &--product {
    padding: 0;
    border: 0;
    &:last-child {
      margin-bottom: 0;
    }
    .search-form {
      position: relative;
      display: flex;
      background-color: var(--white-color);
      border-radius: 999px;
      height: 55px;
      align-items: center;
      padding-right: 10px;

      input {
        flex: 1;
        border-radius: 999px;
        font-size: 14px;
        height: 55px;
      }

      button {
        border: none;
        background-color: var(--theme-color2);
        color: var(--title-color);
        height: 40px;
        width: 40px;
        font-size: 18px;
        border-radius: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: $theme-color;
          color: $white-color;
        }
      }
    }
  }
}
