.vs-header {
  position: relative;
  z-index: 41;
}

.header-logo {
  padding: 20px 0;
  @include respond-below(sm) {
    max-width: 150px;
  }
}

.will-sticky {
  .sticky-active {
    position: fixed;
    top: -100%;
    right: 0;
    left: 0;
    background-color: $white-color;
    transition: all ease 0.8s;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);

    &.active {
      top: 0;
    }
  }
}

.main-menu {
  text-align: center;
  a {
    display: block;
    position: relative;
    font-weight: 700;
    font-size: 18px;
    color: var(--title-color);
    @include lg {
      font-size: 16px;
    }

    &:hover {
      color: $theme-color;
    }
  }

  & > ul {
    & > li {
      margin: 0 18px;
      @include respond-below(xl) {
        margin: 0 10px;
      }
      & > a {
        padding: 37px 0;
        .new-label {
          position: absolute;
          top: -10px;
          right: -27px;
          font-size: 11px;
          border-radius: 3px;
        }

        .has-new-lable {
          position: relative;
        }
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      display: inline-block;
      position: relative;

      &.menu-item-has-children {
        > a {
          &:after {
            content: "\f078";
            position: relative;
            font-family: $icon-font;
            margin-left: 5px;
            font-size: 0.8rem;
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        > ul.sub-menu,
        ul.mega-menu {
          visibility: visible;
          opacity: 1;
          margin-top: 0;
          z-index: 9;
        }
      }
    }
  }

  ul.sub-menu,
  ul.mega-menu {
    position: absolute;
    text-align: left;
    top: 100%;
    left: 0;
    background-color: $white-color;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    visibility: hidden;
    min-width: 190px;
    width: max-content;
    padding: 7px;
    left: -14px;
    margin-top: 50px;
    opacity: 0;
    z-index: -1;
    border-bottom: 3px solid $theme-color;
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.09),
      0px 3px 0px 0px rgba(231, 13, 60, 0.004);
    transform-origin: top center;
    transition: margin-top 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s,
      opacity 0.4s ease-in-out 0s, z-index 0s;

    a {
      font-size: 16px;
      line-height: 30px;
    }
  }

  ul.sub-menu {
    padding: 18px 20px;
    left: -27px;

    &:before {
      content: "";
      position: absolute;
      left: 34.5px;
      top: 30px;
      width: 1px;
      background-color: #ededed;
      height: calc(100% - 60px);
      opacity: 0;
    }

    li {
      display: block;
      margin: 0 0;
      padding: 3px 9px;

      &.menu-item-has-children {
        > a:after {
          content: "\f105";
          float: right;
          top: 3px;
        }
      }

      a {
        position: relative;
        padding-left: 21px;

        &:before {
          content: "\f111";
          position: absolute;
          top: 9px;
          left: 0;
          font-family: $icon-font;
          width: 11px;
          height: 11px;
          text-align: center;
          border-radius: 50%;
          display: inline-block;
          font-size: 0.2em;
          line-height: 11.5px;
          color: $theme-color;
          font-weight: 700;
          background-color: $white-color;
          box-shadow: inset 0px 2px 4px 0px rgba(#ad8858, 0.4);
        }
      }

      ul.sub-menu {
        left: 100%;
        right: auto;
        top: 0;
        margin: 0 0;
        margin-left: 20px;

        li {
          ul {
            left: 100%;
            right: auto;
          }
        }
      }
    }
  }

  .mega-menu-wrap {
    position: static;
  }

  ul.mega-menu {
    display: flex;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    max-width: var(--main-container);
    padding: 20px 15px 23px 15px;
    left: 50%;
    transform: translateX(-50%);

    .main-menu ul.mega-menu > li > ul > li > a {
      position: relative;
    }

    li {
      display: block;
      width: 100%;
      padding: 0 15px;

      li {
        padding: 4px 0;
      }

      a {
        display: inline-block;
      }
    }

    > li {
      > a {
        display: block;
        padding: 0;
        padding-bottom: 15px;
        margin-bottom: 10px;
        text-transform: capitalize;
        letter-spacing: 1px;
        font-weight: 700;
        color: $title-color;
        border-color: $theme-color;

        &::after,
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 15px;
          height: 1px;
          background-color: $theme-color;
        }

        &::after {
          width: calc(100% - 20px);
          left: 20px;
        }

        &:hover {
          padding-left: 0;
        }
      }

      > ul {
        > li {
          > a {
            &:before {
              content: "\f105";
              font-family: $icon-font;
              font-weight: 400;
              margin: 0 10px 0 0;
            }
          }
        }
      }
    }
  }
}
.menu-style2 {
  a {
    color: var(--white-color);
    font-size: 18px;
    @include lg {
      font-size: 16px;
    }
  }
  ul.sub-menu a,
  ul.mega-menu a {
    color: var(--title-color);
    &:hover {
      color: var(--theme-color);
    }
  }
}

.header-text {
  margin: 0;
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-weight: 600;
  span {
    font-weight: 800;
    padding-left: 5px;
  }
  i {
    margin-right: 14px;
    font-size: 36px;
    display: inline-flex;
  }
  &.style2 {
    color: var(--white-color);
    font-weight: 400;
    i {
      color: var(--theme-color2);
    }
  }
}

.header-info {
  display: flex;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 28px;
  margin-left: 40px;
  padding-left: 40px;
  border-left: 1px solid #e7ebf3;

  &:nth-child(2) {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }

  &_icon {
    color: $theme-color;
    font-size: 24px;
    margin-right: 20px;
  }

  &_label {
    font-size: 14px;
    color: $body-color;
    display: block;
    margin-bottom: 2px;
  }

  &_link {
    color: $title-color;
    font-size: 20px;
    font-weight: 600;
    margin: 0;

    a {
      color: inherit;

      &:hover {
        color: $theme-color;
      }
    }
  }
}

.header-search {
  display: inline-flex;
  max-width: 100%;
  align-items: flex-end;
  margin-right: 31px;
  @include smooth-transition;
  input {
    flex: 1;
    border: none;
    padding: 10px 0px 0 12px;
    height: 25px;
    width: 68px;
    border-radius: 0;
    transition: all ease 0.4s;
    font-size: 16px;
    font-weight: 500;
  }

  button {
    border: none;
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    padding: 0;
    font-size: 22px;
  }
  &.style2 {
    input {
      background-color: transparent;
      color: var(--white-color);
      &::placeholder {
        color: var(--white-color);
      }
    }
    button {
      color: var(--white-color);
    }
  }
}

.header-links {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: $title-color;
    font-family: $title-font;
    padding: 0 30px 0 0;
    margin: 0 26px 0 0;
    border-right: 1px solid #cad4f1;
    line-height: 23px;

    &:last-child {
      margin-right: 0;
      padding-right: 0;
      border-right: none;
    }
  }

  i {
    color: $theme-color;
    margin: 0 10px 0 0;
  }

  a {
    color: inherit;

    &:hover {
      color: $theme-color;
    }
  }

  &.style-white {
    i,
    li {
      color: $white-color;
      border-color: #558eff;
    }
  }
}

.header-social {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 16px;
  @include respond-below(md) {
    justify-content: center;
  }
  a {
    display: inline-flex;
    color: var(--title-color);
    width: 37px;
    height: 37px;
    align-items: center;
    justify-content: center;
    background-color: rgba(#fff, 85%);
    border-radius: 50%;

    &:hover {
      background-color: $title-color;
      color: var(--theme-color2);
    }
  }

  &.style2 {
    a {
      background-color: rgba(255, 255, 255, 0.21);
      color: var(--white-color);
      &:hover {
        background-color: var(--theme-color2);
        color: var(--title-color);
      }
    }
  }

  &.style-white {
    .social-title,
    a {
      color: $white-color;
    }

    a {
      &:hover {
        color: $title-color;
      }
    }
  }
}

.header-call {
  display: flex;
  align-items: center;
  color: var(--text-color);
  i {
    font-size: 36px;
    margin-right: 10px;
  }
  span {
    font-size: 16px;
    font-weight: 600;
  }
  a {
    color: var(--text-color);
    margin-left: 5px;
    font-weight: 700;
    &:hover {
      color: var(--theme-color);
    }
  }
  &.style2 {
    color: var(--white-color);
    a {
      color: var(--white-color);
      font-weight: 400;
      &:hover {
        color: var(--theme-color2);
      }
    }
    i {
      color: var(--theme-color2);
    }
  }
}

.header-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  button,
  a {
    position: relative;
    height: 56px;
    width: 56px;
    border: 1px solid var(--vs-border-color);
    font-size: 22px;
    border-radius: 50%;
    background-color: transparent;
    @include smooth-transition;
    color: var(--body-color);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include respond-below(sm) {
      width: 50px;
      height: 50px;
    }
    &:hover {
      background-color: var(--theme-color);
      border-color: var(--theme-color);
      color: var(--white-color);
    }
  }
  &.style2 {
    button,
    a {
      color: var(--white-color);
    }
  }
  .sideCartToggler {
    background-color: var(--theme-color);
    color: var(--white-color);
    border-color: var(--theme-color);
    span {
      position: absolute;
      top: -5px;
      right: 0;
      width: 20px;
      height: 20px;
      background-color: var(--title-color);
      font-size: 12px;
      border-radius: 50%;
      font-weight: 600;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-family: var(--title-font);
      color: var(--white-color);
      @include smooth-transition;
    }
    &:hover {
      span {
        background-color: var(--theme-color2);
        color: var(--title-color);
      }
    }
    &.style2 {
      span {
        background-color: var(--theme-color2);
      }
    }
  }
}

.header-box {
  border: 10px solid $theme-color;
  background-color: $white-color;
  padding: 20px;

  &__icon {
    border-radius: 5px;
    margin: 0 15px 0 0;
    font-size: 18px;
    width: 43px;
    height: 43px;
    line-height: 43px;
    text-align: center;
    display: inline-block;
    background-color: $theme-color;
    color: $white-color;
  }

  &__title {
    display: inline-block;
    color: $title-color;
    font-weight: 600;
    font-family: $title-font;
  }

  &__number {
    display: block;
    width: fit-content;
    font-family: $title-font;
    font-size: 22px;
    font-weight: 700;
    color: $theme-color;
    margin: 15px 0 0 0;
  }
}

.header-dropdown {
  > .dropdown-toggle {
    color: $title-color;

    i {
      margin: 0 10px 0 0;
    }

    &:after {
      content: "\f107";
      font-family: $icon-font;
      border: none;
      vertical-align: middle;
      position: relative;
      top: -1px;
      margin-left: 5px;
    }

    &:hover {
      color: $theme-color;
    }
  }

  ul.dropdown-menu {
    padding: 10px 15px;
    border: none;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.07);
    margin: 0;
    margin-left: -15px !important;
    margin-top: 15px !important;
    width: max-content;
    min-width: auto;
    position: relative;
    z-index: 1;
    border: none;

    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      display: block;
      margin: 0 0;
      width: max-content;
      padding: 2px 10px;
      color: $title-color;
      font-weight: 400;
      font-size: 14px;

      &:hover {
        color: $theme-color;
      }

      &:first-child {
        margin-top: 0;
        padding-top: 0;
      }

      &:last-of-type,
      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    &:before {
      content: "";
      position: absolute;
      left: 47px;
      top: -7px;
      @include equal-size(14px);
      background-color: $white-color;
      z-index: -1;
      transform: rotate(45deg);
      border-top: 1px solid #ededed;
      border-left: 1px solid #ededed;
    }
  }

  &.style-white {
    > .dropdown-toggle {
      color: $white-color;

      &:hover {
        text-decoration: underline;
        color: rgba(#fff, 0.7);
      }
    }
  }
}

.login-tab {
  display: flex;
  border-radius: 9999px;
  position: relative;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: inherit;
    border: 2px solid $theme-color;
    z-index: -1;
  }

  a {
    display: inline-block;
    color: $theme-color;
    background-color: transparent;
    font-family: $title-font;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 18px;
    padding: 18.5px 37px;
    line-height: 1;
    border-radius: inherit;

    &:hover,
    &.active {
      color: $white-color;
    }
  }

  .indicator {
    position: absolute;
    display: inline-block;
    left: 0;
    top: 0;
    background-color: $theme-color;
    z-index: -1;
    transition: all ease 0.4s;
    border-radius: inherit;
  }
}

.header-layout1 {
  background-color: var(--white-color);
  .header-top {
    background-color: $theme-color2;
    padding: 7px 0;
  }
}

.header-layout1 {
  .menu-top {
    border-bottom: 1px solid #e7ebf3;
  }
}

.header-layout2 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  .header-top {
    padding: 10px 0;
  }
  .sticky-wrapper {
    border-top: 1.5px solid rgba(255, 255, 255, 15%);
    border-bottom: 1.5px solid rgba(255, 255, 255, 15%);
  }
  .will-sticky .sticky-active {
    background-color: var(--bgSticky-color);
  }
}

.header-layout3 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.header-top {
  &.style3 {
    padding: 10px 0;
  }
}

.sticky-wrapper {
  &.style3 {
    background-color: var(--white-color);
    box-shadow: 0px 21px 25px 0px rgba(4, 4, 4, 0.05);
    border-radius: 50px;
    padding: 0 36px;
    @include respond-below(xl) {
      padding: 0 10px;
    }
    &.will-sticky {
      .sticky-active.active {
        padding: 0 30px;
      }
    }
  }
}
