:root {
  --theme-color: #47b32d;
  --theme-color-light: #2f9916;
  --theme-color2: #ffcb24;
  --title-color: #010101;
  --body-color: #000000;
  --text-color: #4e4d4d;
  --text-color2: #cfcfcf;
  --smoke-color: #eff1f5;
  --secondary-color: #eef2fb;
  --bg1-color: #f9eeea;
  --bg2-color: #f5f5f5;
  --bg3-color: #1f2e21;
  --bg4-color: #fafafa;
  --bg4-color: #d9d9d9;
  --bg4-color: #f7f7f7;
  --bgSticky-color: #071c09;
  --black-color: #000000;
  --white-color: #ffffff;
  --yellow-color: #fec624;
  --rating-color: #edb500;
  --success-color: #28a745;
  --error-color: #dc3545;
  --vs-border-color: #c1c1c1;
  --vs-border-color2: #57794f;
  --vs-border-color3: #d5d5d5;
  --vs-border-color4: #e0e0e0;
  --vs-border-color5: #e4e4e4;
  --vs-border-color6: #d1d1d1;
  --vs-border-color7: #dcdcdc;
  --vs-border-color8: #dbd8d8;
  --vs-border-color9: #c2c2c2;
  --vs-border-color10: #c3c3c3;
  --bg-color2: #4e614a;
  --gray-color: #f6f6f6;
  --banner-color1: #fff1b7;
  --banner-color2: #f9eeea;
  --bg-color1: #2b5022;
  --title-font: "Jost", sans-serif;
  --body-font: "Epilogue", sans-serif;
  --icon-font: "Font Awesome 5 Pro";
  --main-container: 1320px;
  --container-gutters: 30px;
  --section-space: 120px;
  --section-space-mobile: 80px;
  --section-title-space: 60px;
  --ripple-ani-duration: 5s;
}

// Color Variation
$theme-color: var(--theme-color);
$theme-color2: var(--theme-color2);
$title-color: var(--title-color);
$body-color: var(--body-color);
$secondary-color: var(--smoke-color);
$smoke-color: var(--smoke-color);
$white-color: var(--white-color);
$black-color: var(--black-color);
$yellow-color: var(--yellow-color);
$success-color: var(--success-color);
$error-color: var(--error-color);
$border-color: var(--vs-border-color);

// Font Variation
$icon-font: var(--icon-font);

// Typography
$title-font: var(--title-font);
$body-font: var(--body-font);
$body-font-size: 16px;
$body-line-Height: 26px;
$body-font-weight: 400;
$p-line-Height: 1.63;

// Device Variation
$hd: 1921px; // Large Device Than 1920
$xl: 1500px; // Extra large Device
$ml: 1399px; // Medium Large Device
$lg: 1199px; // Large Device (Laptop)
$md: 991px; // Medium Device (Tablet)
$sm: 767px; // Small Device
$xs: 575px; // Extra Small Device

// Spacing Count with 5x
$space-count: 6;

// Section Space  For large Device
$space: var(--section-space);
$space-extra: calc(var(--section-space) - 30px);

// Section Space On small Device
$space-mobile: var(--section-space-mobile);
$space-mobile-extra: calc(var(--section-space-mobile) - 30px);

// BG Color Mapping
$bgcolorMap: ();
$bgcolorMap: map-merge(
  (
    "theme": $theme-color,
    "smoke": $smoke-color,
    "white": $white-color,
    "black": $black-color,
    "title": $title-color,
  ),
  $bgcolorMap
);

// Overlay Color Mapping
$overlaycolorMap: ();
$overlaycolorMap: map-merge(
  (
    "theme": $theme-color,
    "title": $title-color,
    "white": $white-color,
    "black": $black-color,
  ),
  $overlaycolorMap
);

// Text Color Mapping
$textColorsMap: ();
$textColorsMap: map-merge(
  (
    "theme": $theme-color,
    "title": $title-color,
    "body": $body-color,
    "white": $white-color,
    "yellow": $yellow-color,
    "success": $success-color,
    "error": $error-color,
  ),
  $textColorsMap
);

// Font Mapping
$fontsMap: ();
$fontsMap: map-merge(
  (
    "icon": $icon-font,
    "title": $title-font,
    "body": $body-font,
  ),
  $fontsMap
);
