.slick-track > [class*="col"] {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.slick-track {
  min-width: 100%;
}

.slick-slide img {
  display: inline-block;
}

.slick-dots {
  list-style-type: none;
  padding: 2px 0;
  margin: 40px 0 30px 0;
  line-height: 0;
  text-align: center;
  height: -moz-max-content;
  height: max-content;

  li {
    display: inline-block;
    margin-right: 25px;

    &:last-child {
      margin-right: 0;
    }
  }

  button {
    font-size: 0;
    padding: 0;
    background-color: transparent;
    width: 8px;
    height: 8px;
    line-height: 0;
    border-radius: 9999px;
    border: none;
    background-color: var(--dots-bg, #000);
    transition: all ease 0.4s;
    position: relative;

    &:hover {
      border-color: $theme-color;
    }

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 30px;
      height: 30px;
      margin: -15px 0 0 -15px;
      border: 2px solid $theme-color;
      border-radius: 50%;
      transition: all ease 0.4s;
      opacity: 0;
      visibility: hidden;
    }
  }

  .slick-active {
    button {
      background-color: $theme-color;

      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.slick-arrow {
  display: inline-block;
  padding: 0;
  background-color: $white-color;
  position: absolute;
  top: 50%;
  border: 1px solid $smoke-color;
  left: var(--pos-x, -100px);
  width: var(--icon-size, 50px);
  height: var(--icon-size, 50px);
  font-size: var(--icon-font-size, 18px);
  margin-top: calc(var(--icon-size, 50px) / -2);
  z-index: 2;
  border-radius: 50%;

  &.default {
    position: relative;
    --pos-x: 0;
    margin-top: 0;
  }

  &.slick-next {
    right: var(--pos-x, -100px);
    left: auto;
  }

  &:hover {
    background-color: $theme-color;
    color: $white-color;
    border-color: transparent;
  }
}

.arrow-margin {
  .slick-arrow {
    top: calc(50% - 30px);
  }
}

.arrow-wrap {
  .slick-arrow {
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    .slick-arrow {
      opacity: 1;
      visibility: visible;
    }
  }
}

.dot-style2 {
  .slick-dots {
    margin-top: 10px;
    margin-bottom: 30px;

    button {
      width: 10px;
      height: 10px;
      border-radius: 9999px;
      background-color: $theme-color;

      &:before {
        display: none;
      }

      &:hover {
        background-color: $title-color;
      }
    }

    li {
      margin: 0 5px;

      &.slick-active {
        button {
          width: 40px;
        }
      }
    }
  }
}

@include xl {
  .slick-arrow {
    --arrow-horizontal: -20px;
  }
}

@include ml {
  .slick-arrow {
    --arrow-horizontal: 40px;
  }
}

@include md {
  .slick-arrow {
    --icon-size: 40px;
    margin-right: 40px;

    &.slick-next {
      margin-right: 0;
      margin-left: 40px;
    }
  }

  .slick-dots {
    margin: 8px 0 38px 0;
  }
}

.vs-carousel {
  &.style2 {
    .slick-dots {
      button {
        background-color: var(--title-color);
        &::before {
          border-color: var(--title-color);
        }
      }
      .slick-active {
        button {
          background-color: var(--theme-color);
          &::before {
            border-color: var(--theme-color);
          }
        }
      }
    }
  }
}
