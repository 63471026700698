.form-style1 {
  background-color: $theme-color;
  --bs-gutter-x: 20px;
  padding: 70px 80px 80px 80px;

  .form-title {
    color: $white-color;
    text-align: center;
    margin: 0 0 27px 0;
    padding: 0 0 17px 0;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      // margin: 0 0 0 -50px;
      height: 1px;
      border-radius: 5px;
      background-color: #0844bc;
      background-color: $white-color;
      opacity: 0.3;
    }
  }

  .vs-btn {
    width: 100%;
  }

  textarea,
  input {
    height: 55px;
    background-color: $white-color;
    font-size: 16px;
    font-family: $body-font;
    color: $body-color;
    border-radius: 5px;
  }

  textarea {
    min-height: 180px;
    resize: none;
  }
}

.newsletter-form,
.newsletter-form2,
.newsletter-form3 {
  border-radius: 0;
  padding: 40px 0px;
  background-color: var(--white-color);
  .title-area {
    margin-bottom: 0;
    display: flex;
    gap: 23px;
    align-items: center;
    flex-wrap: wrap;
    text-align: left;
    @include respond-below(md) {
      justify-content: center;
    }
    .title-icon {
      flex: none;
    }
    .sec-title {
      font-size: 26px;
      font-weight: 800;
      margin-bottom: 5px;
      @include respond-below(sm) {
        font-size: 22px;
      }
    }
  }
  .sec-text {
    margin-bottom: 0;
  }
  .form-control {
    margin-bottom: 0;
    height: 60px;
    min-width: 493px;
    border-radius: 30px;
    background-color: transparent;
    width: auto;
    &:focus {
      border-color: var(--theme-color);
    }
    @include respond-below(lg) {
      min-width: 250px;
    }
    @include respond-below(sm) {
      min-width: 100%;
    }
  }
  .vs-btn {
    position: relative;
    top: 0;
    right: 0;
    background-color: var(--theme-color);
    color: var(--white-color);
    padding: 22px 30px;
    flex: none;
    @include respond-below(sm) {
      flex: auto;
    }
    &:hover {
      background-color: var(--theme-color2);
      color: var(--title-color);
    }
  }
  .form-group {
    display: flex;
    gap: 17px;
    flex-wrap: wrap;
  }
}

.newsletter-form2 .form-group {
  @include respond-below(md) {
    justify-content: center;
  }
}

.calc-form {
  background-color: var(--theme-color2);
  margin-left: -300px;
  padding: 80px 100px 80px 300px;
  position: relative;
  border-radius: 30px;
  @include respond-below(md) {
    margin-left: 0;
    padding: 20px;
  }
  .sec-title {
    font-size: 45px;
    @include respond-below(lg) {
      font-size: 40px;
    }
  }
  .form-control {
    border-radius: 27px;
    border: 0;
    height: 54px;
    margin-bottom: 35px;
  }
  .vs-btn {
    padding: 17px 65px;
    &::before,
    &::after {
      background-color: var(--title-color);
    }
    &:hover {
      color: var(--white-color);
    }
  }
}

.newsletter-form2,
.newsletter-form3 {
  background-color: var(--theme-color2);
  border-radius: 50px;
  padding: 53px 65px;
  position: relative;
  z-index: 2;
  @include respond-below(sm) {
    padding: 15px;
  }
  .form-control {
    min-width: 435px;
    width: auto;
  }
}

.newsletter-form2 {
  .form-group {
    gap: 9px;
  }
  .form-control {
    border-color: var(--vs-border-color);
    background-color: var(--white-color);
    @include respond-below(xlg) {
      min-width: 420px;
    }
    @include respond-below(xl) {
      min-width: 267px;
    }
  }
  .vs-btn {
    &::before,
    &::after {
      background-color: var(--title-color);
    }
    &:hover {
      color: var(--white-color);
    }
  }
}

.newsletter-form3 {
  padding: 30px 30px 0 80px;
  @include respond-below(xl) {
    padding: 15px 15px 0 15px;
  }
  @include respond-below(md) {
    text-align: center;
    padding: 30px;
  }
  @include respond-below(sm) {
    padding: 15px;
  }
  .sec-title {
    font-size: 50px;
    margin: -0.25em 0 20px 0;
    max-width: 88%;
    @include respond-below(xl) {
      font-size: 40px;
    }
    @include respond-below(lg) {
      font-size: 30px;
    }
    @include respond-below(md) {
      max-width: 100%;
    }
  }
  .sec-text {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .form-control {
    height: 62px;
    background-color: var(--white-color);
    border: 0;
    flex: auto;
    @include respond-below(md) {
      min-width: 100%;
    }
  }
  .newsletter-thumb {
    border-radius: 50px;
    overflow: hidden;
    margin-bottom: -30px;
    @include respond-below(xl) {
      max-width: 500px;
    }
    @include respond-below(lg) {
      max-width: 420px;
      margin: 0 auto -30px;
    }
    @include respond-below(md) {
      max-width: 100%;
      margin: 0 auto 0px;
    }
    & > img {
      width: 100%;
    }
  }
  .vs-btn {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    @include respond-below(sm) {
      position: static;
    }
    &::before,
    &::after {
      background-color: var(--title-color);
    }
    &:hover {
      color: var(--white-color);
    }
  }
}

.reviews-form {
  background-color: var(--bg2-color);
  padding: 60px;
  border-radius: 30px;
  margin-bottom: 30px;
  @include respond-below(sm) {
    padding: 20px;
  }
  .form-group {
    margin-bottom: 20px;
    &:last-child {
      margin-top: 10px;
    }
  }
  textarea.form-control {
    padding-top: 20px;
  }
  .form-control {
    height: 60px;
    border-radius: 30px;
    &:focus {
      border-color: var(--theme-color);
    }
  }
  .blog-inner-title {
    padding-bottom: 0;
    margin-bottom: 10px;
    &::after {
      display: none;
    }
  }
  .form-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include respond-below(sm) {
      justify-content: center;
      flex-direction: column;
      text-align: center;
    }
    .form-left {
      margin-bottom: 30px;
      @include respond-below(sm) {
        margin-bottom: 10px;
      }
    }
    .rating-select {
      margin-bottom: 30px;
      margin-top: 0;
      label {
        margin-right: 0;
        text-align: right;
        margin-bottom: 10px;
      }
      .stars {
        font-size: 18px;
        a {
          height: 20px;
          width: 20px;
          &::after {
            height: 20px;
            width: 20px;
          }
          &::before {
            color: var(--text-color);
            font-weight: 600;
          }
        }
      }
      label {
        display: block;
        color: var(--text-color);
        @include respond-below(sm) {
          text-align: center;
        }
      }
    }
  }
}

.form-style {
  padding: 60px;
  background-color: var(--bg2-color);
  border-radius: 50px;
  .sec-title {
    margin: -0.25em 0 10px 0;
  }
  .sec-text {
    margin: 0 0 30px 0;
  }
  .form-control {
    min-height: 62px;
    border-radius: 999px;
    border: 1px solid var(--vs-border-color);
  }
  .form__header__icon {
    position: absolute;
    right: 23px;
    top: 23px;
    @include equal-size(72px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-color2);
    border-radius: 50%;
  }
  .form-group {
    &:last-child {
      margin-bottom: 0;
    }
  }
  .form__check {
    .form__label {
      margin-bottom: 0;
      text-decoration: underline;
      font-weight: 500;
      @include smooth-transition;
      &:hover {
        color: var(--theme-color);
      }
    }
  }
  .form__link {
    color: var(--title-color);
    font-weight: 500;
    text-decoration: underline;
    &:hover {
      color: var(--theme-color);
    }
  }
  &.style2 {
    background-color: var(--bg3-color);
    .form-control {
      border-color: var(--white-color);
    }
  }
}

.form-style2 {
  flex: 1;
  padding: 65px;
  .form-control {
    height: 62px;
    border-radius: 999px;
    &:focus {
      border-color: var(--theme-color2);
    }
  }
  textarea.form-control {
    border-radius: 30px;
    min-height: 120px;
  }
}

.newsletter-form4 {
  @include smooth-transition;
  .form-control {
    height: 62px;
    background-color: var(--white-color);
    border: 0;
    flex: auto;
    border: 2px solid var(--theme-color);
    border-radius: 999px;
    @include smooth-transition;
  }
  .vs-btn {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--theme-color);
    color: var(--white-color);
    padding: 22px 30px;
    flex: none;
    &:hover {
      background-color: var(--theme-color2);
      color: var(--title-color);
    }
  }
  &:hover,
  &:focus {
    .form-control {
      border-color: var(--theme-color2);
      @include smooth-transition;
    }
  }
}
